import { FC, Fragment, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Grid, List, ListItem, Stack, Typography } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'

import { ColorBox, Container, InfoList } from 'components'
import { clinics, benefitsList, districts, doctors, Clinic } from 'data'
import useViewport from 'hooks/useViewport'

const scrollStyles = {
    overflowY: 'auto',
    '::-webkit-scrollbar': {
        width: '16px',
        backgroundColor: '#F5F5F5',
    },
    '::-webkit-scrollbar-thumb': {
        backgroundColor: '#2A1B58',
        borderRadius: '8px',
    },
}

const Health: FC = () => {
    const navigate = useNavigate()
    const { isXS, isMobile, isDesktop } = useViewport()

    const [category, setCategory] = useState<'clinics' | 'doctors' | 'benefits' | ''>('')
    const [searchedValue, setSearchedValue] = useState<string>('')
    const [filteredClinics, setFilteredClinics] = useState<any>(clinics)
    const [filteredDoctors, setFilteredDoctors] = useState(doctors)
    const [selectedDistrict, setSelectedDistrict] = useState('')

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchedValue(event.target.value)
    }

    const handleSearchClick = useCallback(() => {
        if (searchedValue.trim() === '') {
            setFilteredClinics(clinics)
        } else {
            const filteredClinics = clinics.filter(
                (clinic: Clinic) =>
                    clinic.name.toLowerCase().includes(searchedValue.toLowerCase()) ||
                    clinic.address.toLowerCase().includes(searchedValue.toLowerCase())
            )
            setFilteredClinics(filteredClinics)
        }
    }, [searchedValue])

    const handleCategoryClick = (categoryName: 'clinics' | 'doctors' | 'benefits') => {
        category === categoryName ? setCategory('') : setCategory(categoryName)
    }

    const handleDistrictClick = (district: string) => {
        if (selectedDistrict === district) {
            setSelectedDistrict('')
            setFilteredDoctors(doctors)
        } else {
            setSelectedDistrict(district)
            const filteredDoctors = doctors.filter((doctor) =>
                district === 'რეგიონები' ? doctor.city !== 'თბილისი' : doctor.district === district
            )
            setFilteredDoctors(filteredDoctors)
        }
    }

    useEffect(() => {
        if (searchedValue) {
            handleSearchClick()
        }
    }, [searchedValue, handleSearchClick])

    return (
        <Container color='#FF0052'>
            <Stack direction='row' justifyContent='space-between' alignItems='center' mb='32px'>
                <ColorBox
                    icon='health'
                    backgroundColor='#FF0052'
                    height='100px'
                    width='100px'
                    maxWidth='100px'
                    iconSize='40px'
                    fontSize='12px'
                >
                    ჯანმრთელობა
                </ColorBox>
                <Button
                    onClick={() => navigate(-1)}
                    sx={{
                        borderRadius: '24px',
                        '&:hover, &:focus': {
                            backgroundColor: '#F5F5F5',
                        },
                    }}
                >
                    <ArrowBack fontSize='large' style={{ color: '#2A1B58' }} />
                </Button>
            </Stack>
            <Stack
                direction={isDesktop ? 'row-reverse' : 'column'}
                gap='32px'
                justifyContent='space-between'
                alignItems='center'
                mt={isDesktop ? '16px' : ''}
            >
                <Typography fontFamily='Firago' width={isDesktop ? '60%' : '100%'}>
                    ჩვენთვის, როგორც ძლიერი სამედიცინო ჰოლდინგის წევრი კომპანიისთვის, ჯანმრთელობის დაზღვევა პრიორიტეტული
                    მიმართულებაა. ჰოლდინგის მასშტაბით ჩვენ გვაქვს წვდომა უნივერსალურ სამედიცინო სერვისებზე.
                    დაზღვევისთანავე თქვენ გემსახურებათ პირადი ექიმი და კომპანიის 24 საათიანი ცხელი ხაზი.
                </Typography>
                <Grid
                    container
                    bgcolor='#F5F5F5'
                    borderRadius='24px'
                    boxSizing='border-box'
                    padding='16px'
                    width={isDesktop ? '40%' : '100%'}
                >
                    <Grid item container xs={isXS ? 12 : 6}>
                        <Grid item key='clinics' xs={6}>
                            <ColorBox
                                icon='clinics'
                                fontSize='14px'
                                height='100px'
                                minHeight='36px'
                                backgroundColor='transparent'
                                iconSize={isMobile ? (isXS ? '48px' : '32px') : '48px'}
                                textColor={category !== 'clinics' ? '#2A1B58' : '#FF0052'}
                                onClick={() => handleCategoryClick('clinics')}
                            >
                                კლინიკები
                            </ColorBox>
                        </Grid>

                        <Grid item key='doctors' xs={6}>
                            <ColorBox
                                icon='doctors'
                                fontSize='14px'
                                height='100px'
                                minHeight='36px'
                                backgroundColor='transparent'
                                iconSize={isMobile ? (isXS ? '48px' : '32px') : '48px'}
                                textColor={category !== 'doctors' ? '#2A1B58' : '#FF0052'}
                                onClick={() => handleCategoryClick('doctors')}
                            >
                                ექიმები
                            </ColorBox>
                        </Grid>
                    </Grid>
                    <Grid item container xs={isXS ? 12 : 6}>
                        <Grid item key='benefit' xs={6}>
                            <ColorBox
                                icon='benefit'
                                fontSize='14px'
                                height='100px'
                                minHeight='36px'
                                backgroundColor='transparent'
                                iconSize={isMobile ? (isXS ? '48px' : '32px') : '48px'}
                                textColor={category !== 'benefits' ? '#2A1B58' : '#FF0052'}
                                onClick={() => handleCategoryClick('benefits')}
                            >
                                დამატებითი სარგებელი
                            </ColorBox>
                        </Grid>

                        <Grid item key='private-managers' xs={6}>
                            <ColorBox
                                icon='private-managers'
                                fontSize='14px'
                                height='100px'
                                minHeight='36px'
                                backgroundColor='transparent'
                                iconSize={isMobile ? (isXS ? '48px' : '32px') : '48px'}
                                textColor='#2A1B58'
                                to='/private-managers'
                            >
                                პირადი მენეჯერები
                            </ColorBox>
                        </Grid>
                    </Grid>
                </Grid>
            </Stack>

            {category === 'clinics' && (
                <Box maxWidth={isDesktop ? '50%' : 'inherit'}>
                    <Stack direction='row' alignItems='center' gap='16px' margin='32px 0 16px'>
                        <Box width='100%' maxWidth='400px'>
                            <input
                                type='text'
                                placeholder='მოძებნე სახელით ან მისამართით'
                                value={searchedValue}
                                onChange={handleSearchChange}
                                style={{
                                    height: '44px',
                                    width: '100%',
                                    borderRadius: '12px',
                                    border: '1px solid #FF0052',
                                    padding: '0 12px',
                                    boxSizing: 'border-box',
                                    outline: 'none',
                                }}
                            />
                        </Box>
                    </Stack>
                    <Box maxHeight='300px' padding='8px 16px' borderRadius='8px' boxShadow={3} sx={scrollStyles}>
                        <List>
                            {filteredClinics.length > 0 ? (
                                filteredClinics.map((clinic: Clinic, index: number) => {
                                    const shouldRenderRegion =
                                        index === 0 || clinic.region !== filteredClinics[index - 1].region
                                    return (
                                        <Fragment key={`${clinic.name}${index}`}>
                                            {shouldRenderRegion && (
                                                <ListItem disablePadding>
                                                    <Typography color='#FF0052' fontWeight='bold' fontFamily='Firago'>
                                                        {clinic.region}
                                                    </Typography>
                                                </ListItem>
                                            )}
                                            <ListItem disablePadding>
                                                <Stack direction='column'>
                                                    <Typography fontWeight='bold' fontFamily='Firago'>
                                                        {clinic.name}
                                                    </Typography>
                                                    <Typography fontFamily='Firago'>{clinic.address}</Typography>
                                                </Stack>
                                            </ListItem>
                                        </Fragment>
                                    )
                                })
                            ) : (
                                <Typography fontFamily='Firago'>კლინიკა ვერ მოიძებნა</Typography>
                            )}
                        </List>
                    </Box>
                </Box>
            )}
            {category === 'doctors' && (
                <Stack direction='column' gap='16px' mt='16px' maxWidth={isDesktop ? '50%' : 'inherit'}>
                    <Typography fontFamily='Firago'>
                        ჩვენი ოჯახის ექიმები საქართველოს მასშტაბით 45 ქალაქისა და რაიონის კლინიკაში მოგემსახურებათ.
                    </Typography>
                    <Typography fontWeight='bold' fontFamily='Firago'>
                        მოძებნე უბნის მიხედვით
                    </Typography>
                    <Stack direction='row' flexWrap='wrap' gap='10px' boxSizing='border-box'>
                        {districts.map((district: string) => {
                            return (
                                <ColorBox
                                    backgroundColor='#FF0052'
                                    width='fit-content'
                                    onClick={() => handleDistrictClick(district)}
                                    border={
                                        selectedDistrict === district ? '2px solid #2A1B58' : '2px solid transparent'
                                    }
                                >
                                    {district}
                                </ColorBox>
                            )
                        })}
                    </Stack>
                    <Box maxHeight='300px' padding='8px 16px' borderRadius='8px' boxShadow={3} sx={scrollStyles}>
                        <List>
                            <Stack direction='column' gap='8px'>
                                {filteredDoctors.length > 0 ? (
                                    filteredDoctors.map((option, index) => (
                                        <ListItem key={`${option.doctor}${index}`} disablePadding>
                                            <Stack direction='column'>
                                                <Typography color='#FF0052' fontWeight='bold' fontFamily='Firago'>
                                                    {option.title && option.district}
                                                </Typography>
                                                <Typography fontWeight='bold' fontFamily='Firago'>
                                                    {option.doctor}
                                                </Typography>
                                                <Typography fontFamily='Firago'>
                                                    {option.address}, {option.city}
                                                </Typography>
                                            </Stack>
                                        </ListItem>
                                    ))
                                ) : (
                                    <Typography fontFamily='Firago'>ექიმი ვერ მოიძებნა</Typography>
                                )}
                            </Stack>
                        </List>
                    </Box>
                </Stack>
            )}
            {category === 'benefits' && (
                <Box mt='16px'>
                    <InfoList data={benefitsList} color='#FF0052' noBold />
                </Box>
            )}
        </Container>
    )
}

export default Health
