import { FC } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Grid, Stack, Typography } from '@mui/material'

import { ColorBox, Container, Image, Review } from 'components'
import { reviewers, team } from 'data'
import useViewport from 'hooks/useViewport'
import { ArrowBack, Error } from '@mui/icons-material'

const TeamMember: FC = () => {
    const navigate = useNavigate()
    const { isDesktop, isMobile, isXS } = useViewport()
    const { id } = useParams<{ id: string }>()

    const memberInfo = team.find((member) => member.id === id)

    return (
        <Container noFooter>
            {memberInfo ? (
                <Stack mb='32px' mt={!isMobile ? '16px' : ''}>
                    <Stack direction='row' gap={isMobile ? '16px' : '32px'} alignItems='center'>
                        <Image
                            src={memberInfo.image}
                            alt={memberInfo.name}
                            borderRadius='50%'
                            height={isMobile ? '150px' : '200px'}
                            width={isMobile ? '150px' : '200px'}
                        />
                        <Stack
                            direction='row'
                            justifyContent='space-between'
                            alignItems='flex-start'
                            width={isMobile ? 'calc(100% - 150px)' : 'calc(100% - 200px)'}
                        >
                            <Stack direction='column'>
                                <Typography fontSize={isMobile ? '24px' : '32px'} fontWeight='bold' fontFamily='Firago'>
                                    {memberInfo.name}
                                </Typography>
                                <Typography fontSize='12px' fontStyle='italic' fontFamily='Firago'>
                                    {memberInfo.position}
                                </Typography>
                            </Stack>
                            <Button
                                onClick={() => navigate(-1)}
                                sx={{
                                    borderRadius: '24px',
                                    '&:hover, &:focus': {
                                        backgroundColor: '#F5F5F5',
                                    },
                                }}
                            >
                                <ArrowBack fontSize='large' style={{ color: '#2A1B58' }} />
                            </Button>
                        </Stack>
                    </Stack>
                    <Typography my='32px' fontFamily='Firago'>
                        {memberInfo.info}
                    </Typography>
                    <ColorBox
                        icon='phone'
                        backgroundColor='#2A1B58'
                        width='fit-content'
                        fontSize='20px'
                        margin='16px 0'
                        flexDirection='row'
                        phone={memberInfo.phone}
                    >
                        <Typography fontFamily='Firago' color='white'>
                            დაუკავშირდი {memberInfo.name.split(' ')[0]}ს
                        </Typography>
                    </ColorBox>
                    <ColorBox
                        backgroundColor='#F5F5F5'
                        textColor='#2A1B58'
                        height='72px'
                        fontSize={isXS ? '16px' : '24px'}
                        margin='32px 0 16px'
                    >
                        დაზღვეულები ჩვენ შესახებ
                    </ColorBox>
                    <Stack direction='column' borderRadius='16px' padding='16px' pb='0px'>
                        <Grid container spacing={isDesktop ? 4 : 0}>
                            {reviewers.map((review) => (
                                <Grid item key={review.companyName} xs={isDesktop ? 6 : 12}>
                                    <Review
                                        companyName={review.companyName}
                                        reviewText={review.reviewText}
                                        author={review.author}
                                        imageUrl={review.imageUrl}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Stack>
                </Stack>
            ) : (
                <Stack justifyContent='center' alignItems='center' width='100vw' mt='48px' gap='16px'>
                    <Error style={{ color: '#2A1B58', fontSize: '48px' }} />
                    <Typography fontFamily='Firago' fontSize='20px'>
                        გვერდი ვერ მოიძებნა
                    </Typography>
                </Stack>
            )}
        </Container>
    )
}

export default TeamMember
