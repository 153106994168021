import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Grid, Stack, Typography } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'

import { ColorBox, Container, InfoList } from 'components'
import { propertyBoxes, propertyList } from 'data'
import useViewport from 'hooks/useViewport'

const Property: FC = () => {
    const navigate = useNavigate()
    const { isXS, isS, isMobile, isDesktop } = useViewport()

    return (
        <Container color='#784EA3'>
            <Stack direction='row' justifyContent='space-between' alignItems='center' mb='32px'>
                <ColorBox
                    icon='property'
                    backgroundColor='#784EA3'
                    height='100px'
                    width='100px'
                    maxWidth='100px'
                    iconSize='40px'
                    fontSize='14px'
                >
                    ქონება
                </ColorBox>
                <Button
                    onClick={() => navigate(-1)}
                    sx={{
                        borderRadius: '24px',
                        '&:hover, &:focus': {
                            backgroundColor: '#F5F5F5',
                        },
                    }}
                >
                    <ArrowBack fontSize='large' style={{ color: '#2A1B58' }} />
                </Button>
            </Stack>
            <Typography fontFamily='Firago'>
                უძრავი ქონება თქვენი კაპიტალის მნიშვნელოვანი ნაწილია. დაზღვევით თქვენ იქნებით მშვიდად ნებისმიერი
                საფრთხის შემთხვევაში და არ მოგიწევთ გაუთვალისწინებელი ხარჯების გაღება.
            </Typography>

            <Grid container spacing={2} mt='32px'>
                {propertyBoxes.map((property) => (
                    <Grid item key={property.icon} xs={isDesktop ? 2 : isXS || isS ? 6 : 4}>
                        <ColorBox
                            icon={property.icon}
                            backgroundColor='#784EA3'
                            height='100px'
                            fontSize={isMobile && !isXS ? '12px' : '14px'}
                            iconSize={!isMobile ? '56px' : isXS ? '48px' : '32px'}
                        >
                            {property.title}
                        </ColorBox>
                    </Grid>
                ))}
            </Grid>

            <ColorBox
                backgroundColor='#F5F5F5'
                textColor='#784EA3'
                height='72px'
                fontSize={isXS ? '20px' : '24px'}
                margin='32px 0'
            >
                დაზღვეული რისკები
            </ColorBox>

            <InfoList data={propertyList} color='#784EA3' />
        </Container>
    )
}

export default Property
