// team members
import AnaGotsiridze from 'assets/images/team/ana-gotsiridze.jpg'
import DachiLekishvili from 'assets/images/team/dachi-lekishvili.jpg'
import DavitDolidze from 'assets/images/team/davit-dolidze.jpg'
import EkaterineTavkhelidze from 'assets/images/team/ekaterine-tavkhelidze.jpg'
import GiorgiMamamtavrishvili from 'assets/images/team/giorgi-mamamtavrishvili.jpg'
import TataChkhikvadze from 'assets/images/team/tata-chkhikvadze.jpg'
import KhatoCheishvili from 'assets/images/team/khato-cheishvili.jpg'
import TataBerelidze from 'assets/images/team/tata-berelidze.jpg'

// reviewers
import AlekoMalkhazishvili from 'assets/images/reviewers/aleko-malkhazishvili.jpg'
import AnaMetreveli from 'assets/images/reviewers/ana-metreveli.jpg'
import DavitTsertsvadze from 'assets/images/reviewers/davit-tsertsvadze.jpg'
import TakoPotskhishvili from 'assets/images/reviewers/tako-potskhishvili.jpg'
import TamarMachitadze from 'assets/images/reviewers/tamar-machitadze.jpg'
import IvaneCherqezishvili from 'assets/images/reviewers/ivane-cherqezishvili.jpg'
import MaiaManjgaladze from 'assets/images/reviewers/maia-manjgaladze.jpg'
import NataliOsipova from 'assets/images/reviewers/natali-osipova.jpg'
import TatoKharchilava from 'assets/images/reviewers/tato-kharchilava.jpg'

// private managers
import TamtaUgrekhelidze from 'assets/images/managers/tamta-ugrekhelidze.jpg'
import LanaEdiberidze from 'assets/images/managers/lana-ediberidze.jpg'
import KhatoMakharadze from 'assets/images/managers/khato-makharadze.jpg'
import KhatoQamashidze from 'assets/images/managers/khato-qamashidze.jpg'

export const team = [
    {
        id: 'khato-cheishvili',
        name: 'ხათო ჭეიშვილი',
        position: 'კორპორატიული ბიზნესის დირექტორი',
        image: KhatoCheishvili,
        info: 'ხათო ჭეიშვილი სადაზღვევო კომპანია ალფას კორპორატიული ბიზნესის დირექტორია. მისი საქმიანობა კორპორატიულ სექტორში მოიცავს 10 წელზე მეტ გამოცდილებას, აქედან 7 წელს უშუალოდ სადაზღვევო სფეროში. ხათოს პორტფელში მრავალი კომპანიაა, რომლებიც კვლავინდებურად აგრძელებენ ალფა დაზღვევასთან თანამშრომლობას. მათი ნაწილის რეკომენდაცია შეგიძლიათ მოიძიოთ ჩვენს გვერდზე ან თავად ჰკითხოთ მათ ჩვენი დაზღვევის შესახებ, რათა დარწმუნდეთ ჩვენი სერვისებისა და პირობების უპირატესობებში.',
        phone: '555602540',
    },
    {
        id: 'davit-dolidze',
        name: 'დავით დოლიძე',
        position: 'კორპორატიული ბიზნესის ჯგუფის ხელმძღვანელი',
        image: DavitDolidze,
        info: 'დავით დოლიძე სადაზღვევო კომპანია ალფას კორპორატიული ბიზნესის ჯგუფის ხელმძღვანელია. მისი სამუშაო გამოცდილება 18 წელს ითვლის, საიდანაც 9 წლიანი გამოცდილება აქვს საბანკო სექტორში როგორც საცალო, ასევე კორპორატიული მიმართულებით, უკანასკნელი 9 წლის განმავლობაში დავითი სადაზღვევო სფეროში მოღვაწეობს, საიდანაც ბოლო 3 წელია ალფას კორპორატიულ ჯგუფს ხელმძღვანელობს, სადაც უამრავი წარმატებული პარტნიორული პროექტი აქვს განხორციელებული.',
        phone: '577 78 42 42',
    },
    {
        id: 'giorgi-mamamtavrishvili',
        name: 'გიორგი მამამთავრიშვილი',
        position: 'უფროსი კორპორატიული გაყიდვების მენეჯერი',
        image: GiorgiMamamtavrishvili,
        info: 'გიორგი მამამთავრიშვილი სადაზღვევო კომპანია ალფას უფროსი კორპორატიული გაყიდვების მენეჯერია. გიორგი 2014 წლიდან დაზღვევის მიმართულებით მუშაობს. ალფას კორპორატიული ბიზნესის გუნდის სხვა წევრებთან ერთად, გიორგის პორტფელი მოიცავს მრავალი მიმართულების ბიზნესის პარტნიორებს, რომელთა რეკომენდაციების ნახვა შესაძლებელია ჩვენს გვერდზე.',
        phone: '595091462',
    },
    {
        id: 'tata-chkhikvadze',
        name: 'ტატა ჩხიკვაძე',
        position: 'უფროსი კორპორატიული გაყიდვების მენეჯერი',
        image: TataChkhikvadze,
        info: 'ტატა ჩხიკვაძე სადაზღვევო კომპანია ალფას უფროსი კორპორატიული მენეჯერის პოზიციაზე მუშაობს, მისი სამუშაო გამოცდილება 8 წლიან პერიოდს მოიცავს, საიდანაც პირველ ეტაპზე, საბანკო სფეროში მუშაობდა, ხოლო სადაზღვევო სფეროში მისი გამოცდილება 5 წელს ითვლის, აქედან ბოლო სამი წელია ალფას გუნდის წევრია. მისი წყალობით, ალფამ ბევრი ერთგული და კმაყოფილი მომხმარებელი შეიძინა.',
        phone: '577027931',
    },
    {
        id: 'ekaterine-tavkhelidze',
        name: 'ეკატერინე თავხელიძე',
        position: 'უფროსი კორპორატიული გაყიდვების მენეჯერი',
        image: EkaterineTavkhelidze,
        info: 'ეკატერინე თავხელიძე სადაზღვევო კომპანია ალფას უფროსი კორპორატიული გაყიდვების მენეჯერია. ეკატერინე 5 წელია რაც კორპორატიული დაზღვევის ბიზნესშია, აქედან ბოლო 3 წელია ალფას გუნდში მუშაობს. მის პორტფელში მრავალი მიმართულების მსხვილი კომპანიაა. ეკატერინეს პორტფელი ყოველწლიურად მზარდია, სადაც როგორც განახლებული ხელშეკრულებები, ასევე ახალი კლიენტები ერთიანდებიან.',
        phone: '591706787',
    },
    {
        id: 'dachi-lekishvili',
        name: 'დაჩი ლეკიშვილი',
        position: 'კორპორატიული გაყიდვების მენეჯერი',
        image: DachiLekishvili,
        info: 'დაჩი ლეკიშვილი სადაზღვევო კომპანია ალფას გუნდს 2024 წლიდან შემოუერთდა. დაჩი ალფაში, კორპორატიული გაყიდვების მენეჯერის პოზიციას იკავებს. მას 2 წლამდე გამოცდილება აქვს კორპორატიული გაყიდვების მიმართულებით. დაჩი ალფაში გადმოსვლისთანავე აქტიურად ჩაერთო სადაზღვევო პროდუქტების გაყიდვების მიმართულებით.',
        phone: '577081049',
    },
    {
        id: 'ana-gotsiridze',
        name: 'ანა გოცირიძე',
        position: 'უფროსი კორპორატიული გაყიდვების მენეჯერი',
        image: AnaGotsiridze,
        info: 'ანა გოცირიძე სადაზღვევო კომპანია ალფას უფროსი კორპორატიული გაყიდვების მენეჯერია. ალფაში ანა 2024 წლიდან მუშაობს. სადაზღვევო ბიზნესში მოსვლამდე, მისი 14 წლიანი სამუშაო გამოცდილება, მრავალ განსხვავებულ სფეროს უკავშირდება.',
        phone: '595033488',
    },
    {
        id: 'tata-berelidze',
        name: 'თათა ბერელიძე',
        position: 'კორპორატიული გაყიდვების კოორდინატორი',
        image: TataBerelidze,
        info: 'თათა ბერელიძე სადაზღვევო კომპანია ალფას გუნდს 2022 წლიდან შემოუერთდა, 2024 წლიდან კი კორპორატიული გაყიდვების კოორდინატორის პოზიციას იკავებს. თათას სადაზღვევო კომპანია ალფაში 3 წლიანი მუშაობის გამოცდილება საშუალებას აძლევს, რომ აქტიური მონაწილეობა მიიღოს სადაზღვევო პროდუქტების გაყიდვების მიმართულებით.',
        phone: '558528152',
    },
]

export const products = [
    { title: 'ჯანმრთელობა', icon: 'health', color: '#FF0052', toUrl: '/health' },
    { title: 'ქონება', icon: 'property', color: '#784EA3', toUrl: 'property' },
    { title: 'პასუხისმგებლობა', icon: 'responsibility', color: '#4797D2', toUrl: 'responsibility' },
    { title: 'ტრანსპორტი', icon: 'transport', color: '#FFC600', toUrl: 'transport' },
    { title: 'ტვირთები', icon: 'cargo', color: '#65BD56', toUrl: 'cargo' },
    { title: 'სამოგზაურო', icon: 'travel', color: '#EED952', toUrl: 'travel' },
    { title: 'კიბერ უსაფრთხოება', icon: 'cyber', color: '#13208E', toUrl: 'cyber' },
]

export const privateManagers = [
    { name: 'თამთა უგრეხელიძე', position: 'პირადი მენეჯერი', image: TamtaUgrekhelidze },
    { name: 'ლანა ედიბერიძე', position: 'პირადი მენეჯერი', image: LanaEdiberidze },
    { name: 'ხათო მახარაძე', position: 'კორპორატიული კლიენტების სერვისის სამსახურის უფროსი', image: KhatoMakharadze },
    { name: 'ხათო ქამაშიძე', position: 'პირადი მენეჯერი', image: KhatoQamashidze },
]

export const privateManagersList = [
    { content: 'ოჯახის ექიმთან ჩაწერა' },
    { content: 'ანაზღაურებასთან დაკავშირებული საკითხების მოგვარება' },
    { content: 'ინფორმირება ხელშეკრულების პირობების შესახებ' },
    { content: 'სამედიცინო მომსახურების სქემის გაცნობა' },
    { content: 'ჯანმრთელობის დაზღვევასთან დაკავშირებული პრობლემების დაფიქსირება/მოგავრება' },
    { content: 'დაზღვევისთანავე თქვენ გემსახურებათ პირადი მენეჯერი, პირადი ექიმი და კომპანიის 24 საათიანი ცხელი ხაზი' },
]

export const reviewers = [
    {
        companyName: 'აღმოსავლეთ ევროპის უნივერსიტეტის დირექტორი',
        reviewText:
            'ჩვენი თანამშრომლობიდან გამომდინარე შემიძლია დავახასიათო კომპანია როგორც განვითრებაზე და კლიენტის კეთილდღეობაზე ორიენტირებული',
        author: 'ივანე ჩერქეზიშვილი',
        imageUrl: IvaneCherqezishvili,
    },
    {
        companyName: 'სი კორპორეიშენის დირექტორი/დამფუძნებელი',
        reviewText:
            'მინდა პატივისცემა გამოვხატო კომპანია "ალფას" მიმართ, კარგი გაწეული სამუშაოსთვის, ჩვენი გუნდი კმაყოფილია , "ალფას" სადაზღვევო კომპანიით',
        author: 'ალეკო მალხაზიშვილი',
        imageUrl: AlekoMalkhazishvili,
    },
    {
        companyName: 'Andersen Tbilisi HRM',
        reviewText:
            'კომპანია ალფას კორპორატიული დეპარტამენტის ინდივიდუალური მიდგომა თითოეული ქეისის მიმართ ქმნის განსაკუთრებულ ნდობას ჩვენს თანამშრომლობაში',
        author: 'ნატალი ოსიპოვა',
        imageUrl: NataliOsipova,
    },
    {
        companyName: 'გათბობა-გაგრილების კომპანია „ცელსიუსის“ დირექტორი',
        reviewText:
            'სს "სადაზღვევო კომპანია ალფა" კეთილსინდისიერად, დროულად და ხარისხიანად ასრულებს მასზე დაკისრებულ მოვალეობებს, უზრუნველყოფს ჩვენი კომპანიის თანამშრომლებს პირადი მენეჯერის მომსახურებით, ყოველთვის მზადაა ნებისმიერი საკითხი/პრობლემა განიხილოს ინდივიდუალურად და ორიენტირებულია კლიენტის კმაყოფილებაზე.',
        author: 'თამარ მაჩიტიძე',
        imageUrl: TamarMachitadze,
    },
    {
        companyName: 'ბლექ სი არენას დირექტორი',
        reviewText:
            'სადაზღვევო კომპანია, რომელიც ყველაზე სწრაფად რეაგირებს და აგვარებს ჩვენს ჯანმრთელობასთან დაკავშირებულ საჭიროებებს',
        author: 'ტატო ხარჩილავა',
        imageUrl: TatoKharchilava,
    },
    {
        companyName: 'ასკანელის ძმების HR დირექტორი',
        reviewText: 'პროფესიონალი, საქმიანი, ერთგული, გულშემატკივარი მეგობარი',
        author: 'თაკო ფოხციშვილი',
        imageUrl: TakoPotskhishvili,
    },
    {
        companyName: 'შატო ვარციხის და ვარციხის მარნის დამფუძნებელი',
        reviewText: 'სადაზღვევო კომპანია ალფა სანდოობითა და მომსახურების უმაღლესი ხარისხით გამოირჩევა',
        author: 'დავით ცერცვაძე',
        imageUrl: DavitTsertsvadze,
    },
    {
        companyName: 'დირექტორი',
        reviewText:
            'ჩვენი კომპანია LLC "Coherent Solutions ISsoft Georgia" უკვე 2 წელია, რაც სარგებლობს სადაზღვევო კომპანია "ალფა"-ს კორპორატიული დაზღვევით და სრული პასუხისმგებლობით შემიძლია განვაცხადო, რომ ამ კომპანიის კადრები კვალიფიციურობით, ოპერატიულობითა და მაღალი პასუხისმგებლობით გამოირჩევიან.',
        author: 'ანა მეტრეველი',
        imageUrl: AnaMetreveli,
    },
    {
        companyName: 'რესტორან „ქეთო და კოტეს“ დამფუძნებელი',
        reviewText:
            'კომპანია ალფასთან თანამშრომლობა არის მოსახერხებელი და სასიამოვნო, რადგან მისი ჯგუფი არის მეგობრული, ოპერატიული და მომხმარებლის კომფორტზე ორიენტირებული. წარმატებებს გისურვებთ. მადლობა თანამშრომლობისთვის.',
        author: 'მაია მანჯგალაძე',
        imageUrl: MaiaManjgaladze,
    },
]

export enum REGIONS {
    TBILISI = 'თბილისი',
    RACHA_LECHKHUMI_QVEMO_SVANETI = 'რაჭა – ლეჩხუმი – ქვემო სვანეთი',
    KAKHETI = 'კახეთი',
    IMERETI = 'იმერეთი',
    MCKHETA_MTIANETI = 'მცხეთა-მთიანეთი',
    SAMEGRELO_QVEMO_SVANETI = 'სამეგრელო – ზემო სვანეთი',
    SAMTSKHE_JAVAKHETI = 'სამცხე-ჯავახეთი',
    QVEMO_QARTLI = 'ქვემო ქართლი',
    SHIDA_QARTLI = 'შიდა ქართლი',
    ADJARA = 'აჭარა',
    GURIA = 'გურია',
}

export type Clinic = {
    name: string
    address: string
    region: REGIONS
}

// health

export const clinics: Clinic[] = [
    { name: 'შპს "ავერსის კლინიკა"', address: 'ქ. თბილისი, ვაჟა-ფშაველას გამზ. N27ბ', region: REGIONS.TBILISI },
    {
        name: 'შპს "ავერსის კლინიკა" ბირთვული მედიცინის ცენტრი',
        address: 'ქ. თბილისი, ალ.ყაზბეგის გამზ. N16',
        region: REGIONS.TBILISI,
    },
    {
        name: ' შპს "ავერსის კლინიკა" აღდგენითი თერაპიის ცენტრი',
        address: 'ქ. თბილისი, ალ. ყაზბეგის გამზ. N16',
        region: REGIONS.TBILISI,
    },
    { name: 'შპს "ავერსის კლინიკა" ისნის ფილიალი', address: 'ქ. თბილისი, ნავთლუღის 11-13', region: REGIONS.TBILISI },
    {
        name: 'შპს "ავერსის კლინიკა" თემქის ფილიალი',
        address: 'ქ. თბილისი, თემქის დასახლება, 3 მ/რ, 3 კვარტალი,  კ. 43',
        region: REGIONS.TBILISI,
    },
    {
        name: 'შპს "ავერსის კლინიკა" ბოგდან ხმელნიცკის ფილიალი',
        address: 'ქ. თბილისი, ბერი გაბრიელ სალოსის ქ.N 153ა',
        region: REGIONS.TBILISI,
    },
    {
        name: 'შპს აკადემიკოს ვახტანგ ბოჭორიშვილის კლინიკა',
        address: 'ქ. თბილისი, ყაზბეგის გამზ. N16',
        region: REGIONS.TBILISI,
    },
    {
        name: 'სს "კ. ერისთავის სახელობის ექსპერიმენტული და კლინიკური ქირურგიის ეროვნული ცენტრი',
        address: 'ქ. თბილისი, ჩაჩავას 7',
        region: REGIONS.TBILISI,
    },
    {
        name: 'სს "კ. ერისთავის სახელობის ექსპერიმენტული და კლინიკური ქირურგიის ეროვნული ცენტრის ფილიალი" (ახალი სიცოცხლე)',
        address: 'ქ. თბილისი, ლუბლიანას ქ. 21 ა',
        region: REGIONS.TBILISI,
    },
    {
        name: 'ა(ა)იპ "ჯო ენის საუნივერსიტეტო ჰოსპიტალი',
        address: 'ქ. თბილისი, ლუბლიანას 35',
        region: REGIONS.TBILISI,
    },
    { name: 'შპს "ემ-ემ-ტე ჰოსპიტალი"', address: 'ქ. თბილისი, ლუბლიანას ქ., №5, სართული 9', region: REGIONS.TBILISI },
    {
        name: 'შპს "აკად. გ. ჩაფიძის სახელობის გადაუდებელი კარდიოლოგიის ცენტრი"',
        address: 'ქ. თბილისი, ნინო ჯავახიშვილის 1 (ყოფ. ლუბლიანას 4',
        region: REGIONS.TBILISI,
    },
    {
        name: 'ყელ–ყურ–ცხვირის სნეულებათა ეროვნული ცენტრი ჯაფარიძე–ქევანიშვილის კლინიკა',
        address: 'ქ. თბილისი, თევდორე მღვდლის 13',
        region: REGIONS.TBILISI,
    },
    {
        name: 'შპს "ჯეო ჰოსპიტალს"-ის თბილისის მრავალპროფილური სამედიცინო ცენტრი"',
        address: 'ქ. თბილისი, წინანდლის N9-3',
        region: REGIONS.TBILISI,
    },
    {
        name: 'სსიპ  თსსუ "პირველი საუნივერსიტეტო კლინიკა"',
        address: 'ქ. თბილისი, გუდამაყრის 4',
        region: REGIONS.TBILISI,
    },
    {
        name: 'შპს "წმინდა მიქაელ მთავარანგელოზის სახელობის მრავალპროფილიანი კლინიკური საავადმყოფო"',
        address: 'ქ. თბილისი, ლუბლიანას 21',
        region: REGIONS.TBILISI,
    },
    {
        name: 'შპს "თოდუას კლინიკა"',
        address: 'ქ. თბილისი, თევდორე მღვდლის 13; იაკობ ნიკოლაძის ქ. N10',
        region: REGIONS.TBILISI,
    },
    { name: 'შპს "გიდმედი"', address: 'ქ. თბილისი, ლუბლიანას 2/6', region: REGIONS.TBILISI },

    { name: 'შპს "თბილისის გულის ცენტრი"', address: 'ქ. თბილისი, ვაჟა-ფშაველას 83/11', region: REGIONS.TBILISI },
    {
        name: 'შპს "მედინვესტი-ჰემატოლოგიისა და ტრანსფუზიოლოგიის ინსტიტუტი"',
        address: 'ქ. თბილისი, ბუდაპეშტის ქ.13ბ',
        region: REGIONS.TBILISI,
    },
    {
        name: 'შპს "ონკოლოგიის სამეცნიერო კვლევითი ცენტრი"',
        address: 'ქ. თბილისი, ლევან ჩარკვიანის ქ. #8 (ლისის ტბის მიმდებარედ 4)',
        region: REGIONS.TBILISI,
    },
    {
        name: 'შპს "ფრანგულ-გერმანული ხუჯაძე-გოგნიაშვილის ყელ-ყურ-ცხვირის კლინიკა "',
        address: 'ქ. თბილისი, უზნაძის ქ.,№103',
        region: REGIONS.TBILISI,
    },
    { name: 'შპს "კლინიკური ონკოლოგიის ინსტიტუტი"', address: 'ქ. თბილისი, ლუბლიანას 5', region: REGIONS.TBILISI },
    {
        name: 'შპს "თბილისის გულისა და სისხლძარღვთა კლინიკა"',
        address: 'ქ. თბილისი, ლუბლიანას 18/20',
        region: REGIONS.TBILISI,
    },
    { name: 'შპს "ჰერა 2011"', address: 'ქ. თბილისი, ლუბლიანას 5', region: REGIONS.TBILISI },
    { name: 'შპს "ოქროს საწმისი-21 საუკუნე"', address: 'ქ. თბილისი, წინანდლის 9', region: REGIONS.TBILISI },
    { name: 'შპს "პირველი სამედიცინო ცენტრი"', address: 'ქ. თბილისი, ც. დადიანის 255', region: REGIONS.TBILISI },
    { name: 'შპს "იმედის კლინიკა"', address: 'ქ. თბილისი, დ/დიღომი, მირიან მეფის 10/ა', region: REGIONS.TBILISI },

    {
        name: 'შპს "ზურაბ შაქარაშვილის ონკოჰემატოლოგიური კლინიკა - ლაიფმედი"',
        address: 'ქ. თბილისი, ნინო ჯავახიშვილის ქ. 6, სართ. 2',
        region: REGIONS.TBILISI,
    },
    {
        name: 'შპს "მრავალპროფილური კლინიკა კონსილიუმ მედულა"',
        address: 'ქ. თბილისი, პოლიტკოვსკაიას ქ. 6გ.',
        region: REGIONS.TBILISI,
    },
    { name: 'შპს "დიაკორი"', address: 'ქ. თბილისი, ჩაჩავას 1', region: REGIONS.TBILISI },
    { name: 'შპს "ჯანმრთელობის სახლი"', address: 'ქ. თბილისი, წინანდლის 9', region: REGIONS.TBILISI },
    {
        name: 'შპს "ს.ვირსალაძის სახელობის სამედიცინო პარაზიტოლოგიის ინსტიტუტი 1924"',
        address: 'ქ.თბილისი მიხეილ წინამძღვრიშვილის 110',
        region: REGIONS.TBILISI,
    },
    {
        name: 'შპს "სამკურნალო სადიაგნოსტიკო ცენტრი სამგორი მედი"',
        address: 'ქ. თბილისი, კახეთის გზატკეცილი 23',
        region: REGIONS.TBILISI,
    },
    { name: 'შპს "ჩიჩუების სამედიცინო ცენტრი მზერა"', address: 'ქ. თბილისი, წინანდლის 9', region: REGIONS.TBILISI },
    {
        name: 'შპს აკ. ვ. ივერიელის სახელობის ენდოკრინოლოგია, მეტაბოლოგია, დიეტოლოგიის ცენტრი "ენმედიცი"',
        address: 'ქ. თბილისი, წინანდლის 9; არაყიშვილის ქ, N2; ლუბლიანას ქ. N38ა; მირიან მეფის 15',
        region: REGIONS.TBILISI,
    },
    { name: 'შპს "კარდიოლოგიური კლინიკა გული"', address: 'ქ. თბილისი, წინანდლის 9', region: REGIONS.TBILISI },
    {
        name: 'შპს თვალის მიკროქირურგიის ჯავრიშვილის კლინიკა "ოფთალმიჯი"',
        address: 'ქ. თბილისი, ნინო ჯავახიშვილის ქ. N 7',
        region: REGIONS.TBILISI,
    },
    {
        name: 'შპს "ახალი მზერა"',
        address: 'ქ. თბილისი, ლ. მიქელაძის 19 (ყოფილი აბულაძის 32); ალეკო გობრონიძის ქ.#1კ. -გლდანი',
        region: REGIONS.TBILISI,
    },
    {
        name: 'შპს "თბილისის ბავშვთა ინფექციური კლინიკური საავადმყოფო"',
        address: 'ქ. თბილისი, ჩიქოვანის 14',
        region: REGIONS.TBILISI,
    },
    {
        name: 'თსსუ "გ. ჟვანიას სახელობის პედიატრიის აკადემიური კლინიკა"',
        address: 'ქ. თბილისი, ლუბლიანას ქ.#21',
        region: REGIONS.TBILISI,
    },
    {
        name: 'შპს "ქართულ–გერმანული რეპროდუქციული ცენტრი"',
        address: 'ქ. თბილისი, ვასილ ბარნოვის ქუჩა 51გ',
        region: REGIONS.TBILISI,
    },

    {
        name: 'ა(ა)იპ ნიუ ვიჟენ საუნივერსიტეტო ჰოსპიტალი',
        address: 'ქ. თბილისი, ლუბლიანას 2/6',
        region: REGIONS.TBILISI,
    },
    {
        name: 'შპს "თბილისის ცენტრალური საავადმყოფო"',
        address: 'ქ. თბილისი, ლუბლიანას ქუჩა N5',
        region: REGIONS.TBILISI,
    },
    {
        name: 'შპს "ალექსანდრე ალადაშვილის სახელობის კლინიკა"',
        address: 'ქ.თბილისი, დ. უზნაძის ქ.103',
        region: REGIONS.TBILISI,
    },
    {
        name: 'შპს "აკადემიკოს ნიკოლოზ ყიფშიძის სახელობის ცენტრალური საუნივერსიტეტო კლინიკა"',
        address: 'ქ. თბილისი, ვაჟაფშაველას გამზ. N29',
        region: REGIONS.TBILISI,
    },
    {
        name: 'შპს "ს.ხეჩინაშვილის სახელობის საუნივერსიტეტო კლინიკა"',
        address: 'ქ.თბილისი, ილია ჭავჭავაძის გამზირი N33',
        region: REGIONS.TBILISI,
    },
    { name: 'შპს "ენდოკრინოლოგიის ინსტიტუტი"', address: 'ქ. თბილისი, გლდანი, ქერჩის   6-ვ', region: REGIONS.TBILISI },
    {
        name: 'სს "ვიანი" – ი. ბოკერიას სახელობის რეფერალური ჰოსპიტალი',
        address: 'ქ. თბილისი, ზურაბ იარაჯულის #8 (ყოფილი ქინძმარაულის ქ. N1, შესახვევი 1)',
        region: REGIONS.TBILISI,
    },
    {
        name: 'სს "ვიანი" – მ. იაშვილის სახელობის ბავშვთა ცენტრალური საავადმყოფო',
        address: 'ქ. თბილისი, ლუბლიანას ქ. N2/6',
        region: REGIONS.TBILISI,
    },
    {
        name: 'სს "საქართველოს კლინიკები" - ი. ციციშვილის სახელობის ბავშვთა კლინიკა',
        address: 'ქ. თბილისი, ლუბლიანას ქ. N25',
        region: REGIONS.TBILISI,
    },
    { name: 'სს "ვიანი" – კარაპს მედლაინი', address: 'ქ. თბილისი, ლუბლიანას ქ. N48', region: REGIONS.TBILISI },
    { name: 'შპს "ემბრიო"', address: 'ქ.თბილისი, ლუბლიანას 2/6', region: REGIONS.TBILISI },
    { name: 'შპს "პინეო სამედიცინო ეკოსისტემა"', address: 'ქ. თბილისი, გორგასლის ქ. N93', region: REGIONS.TBILISI },
    { name: 'შპს "გაგუას კლინიკა"', address: 'ქ. თბილისი, ნინო ჯავახიშვილის ქ. N6ა', region: REGIONS.TBILISI },
    {
        name: 'შპს "ისრაელ-საქართველოს სამედიცინო კვლევითი კლინიკა ჰელსიკორი"',
        address: 'ქ. თბილისი, თევდორე მღვდლის ქუჩა #13გ',
        region: REGIONS.TBILISI,
    },
    { name: 'შპს "მედ ინვესტმენტი" მარიჯანი', address: 'ქ. თბილისი, მარიჯანის 2ბ', region: REGIONS.TBILISI },
    { name: 'შპს "მედ ინვესტმენტი" ვაკე', address: 'ქ. თბილისი, აბულაძის ქ.7', region: REGIONS.TBILISI },
    {
        name: 'შპს "მედ ინვესტმენტი" - ვაჟა-ფშაველას ფილიალი',
        address: 'ქ. თბილისი, ვაჟა-ფშაველას 76ბ',
        region: REGIONS.TBILISI,
    },
    {
        name: 'შპს "მედ ინვესტმენტი" - დავით ტატიშვილის სპორტული მედიცინის და რეაბილიტაციის ცენტრი',
        address: 'ქ. თბილისი, ზ. ანჯაფარიძის ქ. I, შესახვევი 2',
        region: REGIONS.TBILISI,
    },
    { name: 'შპს "ტლ მედიკალ" ისანი', address: 'ქ. თბილისი, ბერი გაბრიელ სალოსის ქ.55', region: REGIONS.TBILISI },
    {
        name: 'შპს "დავით ტატიშვილის ჯანმრთელობის ცენტრი"',
        address: 'ქ. თბილისი, პეკინის გამზ. N28',
        region: REGIONS.TBILISI,
    },
    { name: 'შპს "სამედიცინო ცენტრი მედიმედი"', address: 'ქ. თბილისი, მარჯანიშვილის ქ N 9', region: REGIONS.TBILISI },
    { name: 'შპს "იმუნიზაციის ცენტრი"', address: 'ქ. თბილისი, ტაშკენტის 10ა', region: REGIONS.TBILISI },
    { name: 'შპს "რეიმანი"', address: 'ქ. თბილისი, სანდრო ეულის N7', region: REGIONS.TBILISI },
    {
        name: 'შპს მრავალპროფილური საკონსულტაციო - დიაგნოსტიკური ცენტრი „ნინო მედი ქლაბ“',
        address: 'ქ. თბილისი, ცინცაძის 49',
        region: REGIONS.TBILISI,
    },
    { name: 'შპს "ლითოტრიფსია"', address: 'ქ. თბილისი, წინანდლის 27', region: REGIONS.TBILISI },
    {
        name: 'შპს "სამედიცინო პარაზიტოლოგიისა და ტროპიკული მედიცინის კვლევის ინსტიტუტი"',
        address: 'ქ. თბილისი, თამარ მეფის გამზ. #18',
        region: REGIONS.TBILISI,
    },
    {
        name: 'შპს "აუდიოლოგიის ეროვნული ცენტრი"',
        address: 'ქ. თბილისი, თევდორე მღვდლის ქუჩა N13',
        region: REGIONS.TBILISI,
    },
    {
        name: 'შპს "საოჯახო მედიცინის ქართულ-ამერიკული კლინიკა"',
        address: 'ქ. თბილისი, ბერბუკის ქ N7',
        region: REGIONS.TBILISI,
    },
    {
        name: 'შპს "ნეოლაბი"',
        address:
            'ქ. თბილისი, 1.ტაშკენტის ქ. 47; 2.ვარლამ თოფურიას 2ბ (ცენტრალური ფილიალი); 3.ნანეიშვილის ქ.23 (გლდანი)',
        region: REGIONS.TBILISI,
    },
    {
        name: 'შპს სამედიცინო ცენტრი "ციტო"',
        address:
            'ქ. თბილისი, ფალიაშვილის 40 (კონსულტაციები, რადიოლოგია);  ლუბლიანას 15; ხიზანიშვილის 21; მირიან მეფის 9',
        region: REGIONS.TBILISI,
    },
    {
        name: 'შპს "მრჩეველი"',
        address:
            'დიღმის მასივი, ესმა ონიანის 10; ალ.ყაზბეგის გამზ.9; ვ.ბარნოვის ქ. 76; ი.ჭავჭავაძის გამზ.49; 17 შინდისელი გმირის 7; ვასაძის ქ.7',
        region: REGIONS.TBILISI,
    },
    {
        name: 'შპს "ვისტამედი"',
        address: 'ქ. თბილისი, დიღომი, რამაზის ქ. N 28 (მუშაობს 24/7); ქ. თბილისი, ვაკე, შროშის ქ. N14',
        region: REGIONS.TBILISI,
    },
    { name: 'სს "მეგა-ლაბი"', address: 'ქ. თბილისი, ქავთარაძის #23', region: REGIONS.TBILISI },
    {
        name: 'შპს "სინევო საქართველო"',
        address:
            'ქ. თბილისი, წინანდლის N9; იონა ვაკელის ქ. N25ა; ვ. დოლიძის ქ. N46; ქეთევან წამებულის გამზ.N47; ხიზანიშვილის ქ. N52; ლუბლიანას II ჩიხი N 1; წულუკიძის ქ. N11; ბ.ჭიჭინაძის ქ. N8; ცოტნე დადიანი ქ. მე-II მკრ. მე -II კორპუსი; მარჯანიშვილის ქუჩა #31; ფალიაშვილის ქუჩა N27/29; მუხიანის მე-4 მიკრო, N9; თემქა, მე-10 კვარტალი, 28-ე კორპუსი; ი. პეტრიწის ქუჩა, კორპუსი N13; ათონელის ქუჩა, კორპუსი N12; თამარ მეფის გამზ./გ.ჩუბინაშვილის ქ. N29/81; წერეთლის გამზირი N110; ი. სუხიშვილის ქუჩა N1; მოსკოვის გამზირი N39',
        region: REGIONS.TBILISI,
    },
    { name: 'შპს "ჯანმრთელობის ინსტიტური"', address: 'ქ. თბილისი, გოთუას ქ. 3', region: REGIONS.TBILISI },
    { name: 'შპს "გ მედი"', address: 'ქ. თბილისი, ყაზბეგის 44, 2 სართული', region: REGIONS.TBILISI },
    {
        name: 'შპს "დავით აბულაძის ქართულ-იტალიური კლინიკა"',
        address: 'ქ. თბილისი, ფალიაშვილის ქ. 39ა',
        region: REGIONS.TBILISI,
    },
    { name: 'შპს "კლინიკა ლარსზე"', address: 'ქ. თბილისი, ლარსის შეს. N3', region: REGIONS.TBILISI },
    { name: 'შპს "დავით მეტრეველის სამედიცინო ცენტრი"', address: 'ქ.თბილისი, წინანდლის 21', region: REGIONS.TBILISI },
    {
        name: 'შპს "პროფ. ჟორდანიას და პროფ. ხომასურიძის რეპროდუქტოლოგიის ინსტიტუტი"',
        address: 'ქ. თბილისი, თევდორე მღვდლის 13',
        region: REGIONS.TBILISI,
    },
    { name: 'შპს "ი.ჟორდანიას სახელობის კლინიკა"', address: 'ქ. თბილისი, ლუბლიანს ქ. N5', region: REGIONS.TBILISI },
    { name: 'შპს "მედკაპიტალი" – საბურთალო', address: 'ქ. თბილისი, გამრეკელის 19', region: REGIONS.TBILISI },
    { name: 'შპს "მედკაპიტალი" – დიდუბე', address: 'ქ. თბილისი, წერეთლის 138', region: REGIONS.TBILISI },
    {
        name: 'შპს "მედკაპიტალი" – სამგორი',
        address: 'ქ. თბილისი, მოსკოვის გამზირი 4-ე კვ. კორპ. 3',
        region: REGIONS.TBILISI,
    },
    { name: 'შპს "მედკაპიტალი" – გლდანი', address: 'ქ. თბილისი, ილია ვეკუას 18', region: REGIONS.TBILISI },
    {
        name: 'შპს "მედიგრუპი"',
        address: 'ქ.თბილისი თეიმურაზ ბოჭორიშვილის ჩიხი 1 N4 (ყოფილი ხიზაბავრის 4)',
        region: REGIONS.TBILISI,
    },
    {
        name: 'ა(ა)იპ "სპორტული მედიცინისა და მეცნიერების აკადემია"',
        address: '1. ქ. თბილისი, იოანე პეტრიწის 10ა; 2. ქ. თბილისი, უნივერსიტეტის 15ა',
        region: REGIONS.TBILISI,
    },
    {
        name: 'შპს "სასწავლო უნივერსიტეტი გეომედი"',
        address: 'ქ.თბილისი, დ. აღმაშენებლის გამზირი №170',
        region: REGIONS.TBILISI,
    },

    {
        name: 'სს ევექსი – საბურთალოს პოლიკლინიკა',
        address: 'ქ. თბილისი, ვაჟა-ფშაველას გამზ. N40',
        region: REGIONS.TBILISI,
    },
    { name: 'სს ევექსი - ცინცაძის პოლიკლინიკა', address: 'ქ. თბილისი, ცინცაძის ქ.24', region: REGIONS.TBILISI },
    { name: 'სს ევექსი – გლდანის პოლიკლინიკა', address: 'ქ. თბილისი, მარატ ნოზაძის ქ. N8', region: REGIONS.TBILISI },
    {
        name: 'სს ევექსი – ისნის პოლიკლინიკა',
        address: 'ქ. თბილისი, ეთერ კაკულიას 1 შესახვევი N1',
        region: REGIONS.TBILISI,
    },
    { name: 'სს ევექსი – ვარკეთილის პოლიკლინიკა', address: 'ქ. თბილისი, ჯავახეთის ქ. N30', region: REGIONS.TBILISI },
    { name: 'სს ევექსი – მთაწმინდის პოლიკლინიკა', address: 'ქ. თბილისი, რევაზ ლაღიძის N8', region: REGIONS.TBILISI },
    { name: 'სს ევექსი – დიდუბის პოლიკლინიკა', address: 'ქ. თბილისი, წერეთლის გამზ. N123', region: REGIONS.TBILISI },
    { name: 'სს ევექსი – დიდი დიღმის პოლიკლინიკა', address: 'ქ. თბილისი, ი.პეტრიწის N16', region: REGIONS.TBILISI },
    {
        name: 'სს ევექსი – ვაზისუბნის პოლიკლინიკა',
        address: 'ქ. თბილისი, შანდორ პეტეფის #36',
        region: REGIONS.TBILISI,
    },
    {
        name: 'სს ევექსი – გურამიშვილის პოლიკლინიკა',
        address: 'ქ. თბილისი, დ. გურამიშვილის გამზ. #64',
        region: REGIONS.TBILISI,
    },
    { name: 'ა(ა)იპ ნიუ ვიჟენ უნივერსიტეტი', address: 'ქ. თბილისი, ნოდარ ბოხუას ქ. 11', region: REGIONS.TBILISI },
    { name: 'შპს "ავერსი-ფარმა"', address: 'ქ. თბილისი, დავით აღმაშენებლის გამზ. 148/2', region: REGIONS.TBILISI },
    { name: 'შპს "პსპ ფარმა"', address: 'ქ. თბილისი, დ. აღმაშენებლის გამზ. 148/III', region: REGIONS.TBILISI },
    { name: 'სს "გეფა"', address: 'ქ. თბილისი, აკაკი ბელიაშვილის ქ. #142', region: REGIONS.TBILISI },
    { name: 'სს "ემერჯენსი სერვისი"', address: 'ქ. თბილისი, ლუბლიანას 13/6', region: REGIONS.TBILISI },
    { name: 'სს "პედიატრი"', address: 'ქ. თბილისი, უშ. ჩხეიძის 10', region: REGIONS.TBILISI },
    { name: 'შპს "მედი ქლაბ ჯორჯია"', address: 'ქ. თბილისი, ტაშკენტის ქ. N22ა', region: REGIONS.TBILISI },
    { name: 'შპს "მედკაპიტალი" – გლდანი', address: 'ქ. თბილისი, ილია ვეკუას 18', region: REGIONS.TBILISI },
    {
        name: 'შპს "ნიუ დენტი"',
        address: 'ქ. თბილისი, გლდანის I მკ/რ, კარტოგრაფიული ფაბრიკის მიმდებარედ ვეკუას N3',
        region: REGIONS.TBILISI,
    },
    { name: 'შპს "სტაილ სმაილ კლინიკ"', address: 'ქ. თბილისი, ა. ვასაძის ქ. N 24', region: REGIONS.TBILISI },
    {
        name: 'შპს "დავით მამულაშვილის  სტომატოლოგიური კლინიკა"',
        address: 'ქ. თბილისი, თეიმურაზ ბოჭორიშვიის1 ჩიხი N8 (გლდანი, ხიზაბავრის N5)',
        region: REGIONS.TBILISI,
    },
    {
        name: 'შპს "ესთეთიკ სმაილი - სტომატოლოგიური ცენტრი"',
        address: 'ქ. თბილისი, ცოტნე დადიანის 148',
        region: REGIONS.TBILISI,
    },
    { name: 'შპს "ალგანი დენტი"', address: 'ქ. თბილისი, ვახუშტი ბაგრატიონის 19', region: REGIONS.TBILISI },
    {
        name: 'შპს "Dental World"',
        address:
            'ქ. თბილისი, წერეთლის გამზ. 78, კორპ. N8 - შემოსასვლელი სკვერიდან (წერეთლის მე-8 კორპუსი, გოგოლაურის ქ.)',
        region: REGIONS.TBILISI,
    },
    { name: 'შპს "სმაილი"', address: 'ქ. თბილისი, ევდოშვილის ქ. N12', region: REGIONS.TBILISI },
    {
        name: 'ი/მ ნინო ტრაპაიძე სტომატოლოგიური ცენტრი ინოვა',
        address: 'ქ. თბილისი, წერეთლის გამზ. 141ა',
        region: REGIONS.TBILISI,
    },
    { name: 'ი/მ მაია შულაია (მაიმედი)', address: 'ქ. თბილისი, წერეთლის გამზირი 49/51', region: REGIONS.TBILISI },
    {
        name: 'ი/მ თამარ ზათიაშვილი – დენტუსი',
        address: 'ქ. თბილისი, აღმაშენებლის 175/2 (მ.ლებანიძის 2)',
        region: REGIONS.TBILISI,
    },

    {
        name: 'შპს გველესიანის სტომატოლოგიური კლინიკა ალბა-დენტი',
        address: 'ქ. თბილისი, ზურაბ ჭავჭავაძის 9 (ყოფილი შეროზია, მეტრო მარჯანიშვილთან)',
        region: REGIONS.TBILISI,
    },
    { name: 'ი/მ თემურ სუჯაშვილი - დენტალ ლენდი', address: 'ქ. თბილისი, მაზნიაშვილის 12', region: REGIONS.TBILISI },
    { name: 'ი/მ თემურ სუჯაშვილი - დენტალ ლენდი', address: 'ქ. თბილისი, თამარაშვილის 1', region: REGIONS.TBILISI },
    { name: 'შპს ეფლდენტი', address: 'ქ. თბილისი, ფალიაშვილის ქ. #118', region: REGIONS.TBILISI },
    {
        name: 'ი/მ თამარ დოლიძე - სტომატოლოგიური კლინიკა მედი-დენტ+',
        address: 'ქ. თბილისი, ბ. შოშიტაიშვილის 5 (ყოფ. არაყიშვილის I ჩიხი)',
        region: REGIONS.TBILISI,
    },
    { name: 'შპს დენტალ ჰოუმ', address: 'ქ. თბილისი, ირ. აბაშიძის ქ., №40', region: REGIONS.TBILISI },
    { name: 'შპს ნიუდენტი 1', address: 'ქ. თბილისი, პეტრიაშვილის ქ.N32 ტელ.:2292035', region: REGIONS.TBILISI },
    { name: 'შპს ლიდერ-დენტალ ინტერნეიშენალ', address: 'ქ. თბილისი, თარხნიშვილის N 2', region: REGIONS.TBILISI },
    {
        name: 'შპს ავერსის კლინიკა ცენტრალური',
        address: 'ქ. თბილისი, ვ/ფშაველას გამზ. N 27/ბ',
        region: REGIONS.TBILISI,
    },
    { name: 'შპს მედკაპიტალი – საბურთალო', address: 'ქ. თბილისი, გამრეკელის 19', region: REGIONS.TBILISI },
    {
        name: 'შპს ნიუდენტი 1',
        address: 'ქ. თბილისი, ცინცაძის ქ. N40   ტელ.: 2362294;   მიცკევიჩის ქ. 18    ყაზბეგის N29ა',
        region: REGIONS.TBILISI,
    },
    { name: 'შპს სტომატოლოგიური ცენტრი დენტისტრი', address: 'ქ. თბილისი, პეკინის ქ. 17', region: REGIONS.TBILISI },
    {
        name: 'შპს საერთაშორისო სტომატოლოგიის ცენტრი',
        address: 'ქ. თბილისი, შარტავას N 40, მე-5 სართ.',
        region: REGIONS.TBILISI,
    },
    { name: 'შპს დენტაგრამი', address: 'ქ. თბილისი, მიცკევიჩის ქ. N 18', region: REGIONS.TBILISI },
    { name: 'შპს ბელადენტი', address: 'ქ. თბილისი, კოსტავას 72', region: REGIONS.TBILISI },
    { name: 'შპს პი-ეს დენტალი', address: 'ქ. თბილისი, კანდელაკის 9', region: REGIONS.TBILISI },
    { name: 'მიულერდენტი (ი/მ  ავთანდილ ხუჭუა)', address: 'ქ. თბილისი, ვაჟა–ფშაველას 6', region: REGIONS.TBILISI },
    {
        name: 'შპს მ. ტატიშვილის სტომატოლოგიური სპა ცენტრი',
        address: 'ქ. თბილისი, სანდრო ეულის 5ა, მე-4 სართული',
        region: REGIONS.TBILISI,
    },
    {
        name: 'შპს სტომატოლოგიური კლინიკა ჰეფი დენტი',
        address: 'ქ. თბილისი, ნუცუბიძის ქ. N2 ბ 24',
        region: REGIONS.TBILISI,
    },
    { name: 'შპს ზიბექს', address: 'ქ. თბილისი, ვაჟა-ფშაველას გამზ. 65ა', region: REGIONS.TBILISI },
    {
        name: 'ი/მ მადონა ზაქარეიშვილი საოჯახო სტომატოლოგიური ცენტრი პროფიდენტი-პლიუსი',
        address: 'ქ. თბილისი, ქავთარაძის 33',
        region: REGIONS.TBILISI,
    },
    { name: 'შპს დენტალ ჰაუსი', address: 'ქ. თბილისი, ტაშკენტის ქ. 31', region: REGIONS.TBILISI },
    { name: 'შპს ლაიფ დენტი', address: 'ქ. თბილისი, ბურძგლას ქ. 24', region: REGIONS.TBILISI },
    { name: 'შპს დენტალ ცენტრი', address: 'ქ. თბილისი, ბახტრიონის ქ. 26', region: REGIONS.TBILISI },
    {
        name: 'შპს სტომატოლოგიური კლინიკა ქართულ-გერმანული იმპლანტაციის ცენტრი',
        address: 'ქ. თბილისი, ა.მიცკევიჩის ქ.№20',
        region: REGIONS.TBILISI,
    },
    { name: 'შპს სითი სმაილი', address: 'ქ. თბილისი. შ. ნუცუბიძის 82', region: REGIONS.TBILISI },
    { name: 'ი/მ ვასილ ანთაძე (მეგა დენტი)', address: 'ქ. თბილისი. გაგარინის ქ. 5', region: REGIONS.TBILISI },

    {
        name: 'შპს ნინო დადვანის ესთეტიკის და სტომატოლოგიის ცენტრი',
        address: 'ქ. თბილისი, მიცკევიჩის ქ. 25ბ.',
        region: REGIONS.TBILISI,
    },
    { name: 'შპს ნიუ დენტი', address: 'ქ. თბილისი, მიცკევიჩის ქ. 25ბ.', region: REGIONS.TBILISI },
    { name: 'შპს ატლანტა დენტალ კლინიკ 11', address: 'ქ. თბილისი, მიცკევიჩის ქ. 25ბ.', region: REGIONS.TBILISI },
    { name: 'შპს თ.მ. დენტალ კლინიკა', address: 'ქ. თბილისი, მიცკევიჩის ქ. 25ბ.', region: REGIONS.TBILISI },
    {
        name: 'შპს ბიომედი',
        address: 'ქ. თბილისი, დიღმის მასივი, ე. მიქელაძის #3 (ყოფილი მიქელაძის 10 (რუკაზე იძებნება ამ ნომრით))',
        region: REGIONS.TBILISI,
    },
    { name: 'შპს დიადენტი', address: 'ქ. თბილისი, იაკობ ნიკოლაძის 7', region: REGIONS.TBILISI },
    { name: 'შპს დენტექსი-95', address: 'ქ. თბილისი, ლაღიძის ქ. N8', region: REGIONS.TBILISI },
    {
        name: 'შპს სტომატოლოგიური სახლი რუსთაველი',
        address: 'ქ. თბილისი, ალ.ჭავჭავაძის N 2/28',
        region: REGIONS.TBILISI,
    },
    {
        name: 'შპს ქეთევან გოგილაშვილის სტომატოლოგიის ცენტრი ალბიუსი',
        address: 'ქ. თბილისი, თაბუკაშვილის ქ. 27',
        region: REGIONS.TBILISI,
    },
    { name: 'შპს ავერსის კლინიკა ისანი', address: 'ქ. თბილისი, ნავთლუღის 11/13', region: REGIONS.TBILISI },
    {
        name: 'შპს ავერსის კლინიკა ბ/ხმელნიცკი',
        address: 'ქ. თბილისი, ბერი გაბრიელ სალოსის ქ.N 153ა',
        region: REGIONS.TBILISI,
    },
    { name: 'შპს ნიუ დენტი', address: 'ქ. თბილისი, ქ.წამებულის N69', region: REGIONS.TBILISI },
    { name: 'შპს სტომატოლოგიის ცენტრი სოლო', address: 'ქ. თბილისი, წინანდლის ქ. N21', region: REGIONS.TBILISI },
    {
        name: 'შპს საერთაშორისო სტომატოლოგიის ცენტრი',
        address: 'ქ. თბილისი, ქეთევან წამებულის 47/49 (ბოჭორმის 8)',
        region: REGIONS.TBILISI,
    },
    {
        name: 'ი/მ ქეთევან ბარბაქაძე - სპანდერაშვილის სტომატოლოგიური კლინიკა',
        address: 'ქ. თბილისი, თ. დავითაიას ქ. 1ბ',
        region: REGIONS.TBILISI,
    },
    { name: 'შპს ნიუ დენტი', address: 'ქ. თბილისი, ჯავახეთის ქ.N30', region: REGIONS.TBILISI },
    {
        name: 'ი/მ დიანა ხაბაზიშვილი – I dent',
        address: 'ქ. თბილისი, ქინძმარაულის შესახვევი N3',
        region: REGIONS.TBILISI,
    },

    {
        name: 'შპს რეგიონული ჯანდაცვის ცენტრი - ამბროლაური',
        address: 'ამბროლაური, ბრატისლავა-რაჭის ქ.11',
        region: REGIONS.RACHA_LECHKHUMI_QVEMO_SVANETI,
    },
    {
        name: 'შპს რეგიონული ჯანდაცვის ცენტრი - ცაგერი',
        address: 'ცაგერი, რუსთაველის 31',
        region: REGIONS.RACHA_LECHKHUMI_QVEMO_SVANETI,
    },
    {
        name: 'შპს რეგიონული ჯანდაცვის ცენტრი - ლენტეხი',
        address: 'ლენტეხი, კ. მეშველიანის N4',
        region: REGIONS.RACHA_LECHKHUMI_QVEMO_SVANETI,
    },
    {
        name: 'შპს რეგიონული ჯანდაცვის ცენტრი - ონი',
        address: 'ონი, ვახტანგ მე– 6 ქ N10',
        region: REGIONS.RACHA_LECHKHUMI_QVEMO_SVANETI,
    },
    {
        name: 'ამხანაგობა სანო-კლინიკა',
        address: 'ამბროლაური, ბრატისლავა-რაჭის ქ., ჩიხი 1, #1',
        region: REGIONS.RACHA_LECHKHUMI_QVEMO_SVANETI,
    },
    {
        name: 'ი/მ თამარ ბურჯალიანი "ალფა-დენტი"',
        address: 'ცაგერი, კოსტავას ქ.,22',
        region: REGIONS.RACHA_LECHKHUMI_QVEMO_SVANETI,
    },

    {
        name: 'ააიპ ფრანგული სამედიცინო ცენტრი კახეთი-იონი',
        address: 'გურჯაანი, რუსთაველის 22/ა',
        region: REGIONS.KAKHETI,
    },
    {
        name: 'შპს ავერსის კლინიკა თელავის ფილიალი',
        address: 'თელავი, ვახტანგ სეხნიაშვილის ქ N3',
        region: REGIONS.KAKHETI,
    },
    {
        name: 'შპს ავერსის კლინიკა გურჯაანის ლაბორატორია',
        address: 'ქ. გურჯაანი, თავისუფლების 51',
        region: REGIONS.KAKHETI,
    },
    { name: 'შპს ავერსის კლინიკა ლაგოდეხის ფილიალი', address: 'ლაგოდეხი, ნინოშვილის ქ.2', region: REGIONS.KAKHETI },
    { name: 'შპს ნეოლაბი', address: 'დედოფლისწყარო რუსთაველის ქ. N57', region: REGIONS.KAKHETI },
    { name: 'შპს სამედიცინო ცენტრი ციტო', address: 'ქ.თელავი ერკლე მეორის ქ. 2', region: REGIONS.KAKHETI },
    {
        name: 'შპს რეგიონული ჯანდაცვის ცენტრი - დედოფლისწყარო',
        address: 'დედოფლისწყარო, ნატროშვილის ქ.',
        region: REGIONS.KAKHETI,
    },
    { name: 'შპს არქიმედეს კლინიკა', address: 'სიღნაღის რაიონი, წნორი, მშვიდობის ქ. 1', region: REGIONS.KAKHETI },
    { name: 'შპს არქიმედეს კლინიკა', address: 'ლაგოდეხი, 9 აპრილის ქუჩა; ჯანელიძის ქ.', region: REGIONS.KAKHETI },
    {
        name: 'შპს "სინევო საქართველო"',
        address:
            'თელავი, ერეკლე მეფის ქ. N5; გურჯაანი, წმინდა ნინოს N1; ლაგოდეხი, ზაქათალის ქუჩა N25; ახმეტა, რუსთაველის ქუჩა N55; ლაგოდეხი, სოფელი კაბალი',
        region: REGIONS.KAKHETI,
    },
    {
        name: 'სპს "მერაბ ნადირაშვილი და კომპანია" სტომატოლოგიური კლინიკა "მინანქარი"',
        address: 'თელავი, ნადიკვრის 9',
        region: REGIONS.KAKHETI,
    },
    { name: 'შპს "სამედიცინო პოლიკლინიკა პულსი"', address: 'თელავი, 26 მაისის ქ.№2', region: REGIONS.KAKHETI },
    { name: 'ი/მ ჯულიეტა ჯავახიშვილი - ჯულია დენტი', address: 'თელავი, წერეთლის ქ. N3', region: REGIONS.KAKHETI },
    { name: 'შპს "საფეხბურთო კლუბი ერთობა"', address: 'ახმეტა, ჩოლოყაშვილის ქუჩა N 52', region: REGIONS.KAKHETI },
    { name: 'ი/მ ნინო ვიშნიაკოვა', address: 'ლაგოდეხი, ქიზიყის 21', region: REGIONS.KAKHETI },
    { name: 'ი/მ მაია სეფაშვილი', address: 'ყვარელი, ჭავჭავაძის ქ. 202', region: REGIONS.KAKHETI },
    {
        name: 'ი/მ ზურაბ სპანდერაშვილი - ლუქს დენტალი',
        address: 'დედოფლისწყარო, ჰერეთის ქ. 2ა',
        region: REGIONS.KAKHETI,
    },

    {
        name: 'შპს "რეგიონული ჯანდაცვის ცენტრი - ბორითი"',
        address: 'ხარაგაულის რაიონი, სოფელი ბორითი',
        region: REGIONS.IMERETI,
    },
    {
        name: 'შპს "ჯეო ჰოსპიტალს - ვანის სამედიცინო ცენტრი"',
        address: 'ვანი, თავისუფლების ქ. #84',
        region: REGIONS.IMERETI,
    },
    {
        name: 'შპს "ჯეო ჰოსპიტალს - ზესტაფონის მრავალპროფილური სამედიცინო ცენტრი"',
        address: 'ზესტაფონი, კეკელიძისა და მელიქიძის ქუჩების გადაკვეთა',
        region: REGIONS.IMERETI,
    },
    {
        name: 'შპს "ჯეო ჰოსპიტალს - ზესტაფონის ამბულატორიული ცენტრი"',
        address: 'ზესტაფონი, დ. აღმაშენებლის I შეს. N1',
        region: REGIONS.IMERETI,
    },
    {
        name: 'შპს "ჯეო ჰოსპიტალს - სამტრედიის მრავალპროფილური სამედიცინო ცენტრი"',
        address: 'სამტრედია, კოსტავას ქ.',
        region: REGIONS.IMERETI,
    },
    {
        name: 'შპს "ჯეო ჰოსპიტალს - სამტრედიის ამბულატორიული ცენტრი"',
        address: 'სამტრედია, გ. ჭანტურიას ქ. №2',
        region: REGIONS.IMERETI,
    },
    {
        name: 'სს "საჩხერის რაიონული საავადმყოფო-პოლიკლინიკური გაერთიანება"',
        address: 'საჩხერე, გომართელის 17',
        region: REGIONS.IMERETI,
    },
    {
        name: 'შპს "რეგიონული ჯანდაცვის ცენტრი" – ო. ჩხობაძის სახ. ქუთაისის მრავალპროფილური სამედიცინო დაწესებულება',
        address: 'ქუთაისი, ო.ჩხობაძის ქ N 20 (ყოფილი ტოლბუხინის ქ.19)',
        region: REGIONS.IMERETI,
    },
    {
        name: 'შპს "ქუთაისის დ. ნაზარიშვილის სახ. საოჯახო მედიცინისა და საოჯახო მედიცინის რეგიონალური სასწავლო"',
        address: 'ქუთაისი, თამარ მეფის 5/7',
        region: REGIONS.IMERETI,
    },
    {
        name: 'შპს "რეგიონული ჯანდაცვის ცენტრი" – ხარაგაული',
        address: 'ხარაგაული, დევდარიანის ქ 41',
        region: REGIONS.IMERETI,
    },
    {
        name: 'შპს "ფერომედი"',
        address: 'ზესტაფონი, მაღლაკელიძის 4',
        region: REGIONS.IMERETI,
    },
    {
        name: 'შპს "ბომონდი"',
        address: 'ქუთაისი, გამსახურდიას 1შეს. 15',
        region: REGIONS.IMERETI,
    },
    {
        name: 'შპს "კლინიკა ბომონდი"',
        address: 'ქუთაისი. ზ. გამსახურდიას ქ. #15',
        region: REGIONS.IMERETI,
    },
    {
        name: 'შპს "კლინიკა-ლჯ"',
        address: 'ქუთაისი, ჩეჩელაშვილის 6ა',
        region: REGIONS.IMERETI,
    },
    {
        name: 'შპს "ქუთაისის საეკლესიო საავადმყოფო – წმინდა დავით აღმაშენებლის სახელობის ქსენონი"',
        address: 'ქუთაისი, ახალგაზრდობის გამზირი 21',
        region: REGIONS.IMERETI,
    },
    {
        name: 'შპს "ჯეო ჰოსპიტალს" – ჭიათურის მრავალპროფილური სამედიცინო ცენტრი',
        address: 'ჭიათურა, გ. ჭანტურიას ქ. №20',
        region: REGIONS.IMERETI,
    },
    {
        name: 'შპს "ხონელიძის კლინიკა"',
        address: 'ქუთაისი, ლორთქიფანიძის 11',
        region: REGIONS.IMERETI,
    },
    {
        name: 'შპს "ჯეო ჰოსპიტალს" – ბაღდათის მრავალპროფილური სამედიცინო ცენტრი',
        address: 'ბაღდათი, კახიანის №4',
        region: REGIONS.IMERETI,
    },
    {
        name: 'შპს სამედიცინო ცენტრი "ციტო"',
        address: 'ქუთაისი, რუსთაველის 27; სამტრედია, რესპუბლიკის 2',
        region: REGIONS.IMERETI,
    },
    {
        name: 'შპს "მრჩეველი-ქუთაისი"',
        address: 'ქუთაისი, ლაღიძის ქ N3',
        region: REGIONS.IMERETI,
    },

    {
        name: 'შპს "დიმიტრი მხეიძის სახელობის ყელ-ყურ-ცხვირის კლინიკა გიდი"',
        address: 'ქუთაისი, აკ. კიბორძალიძისN 9',
        region: REGIONS.IMERETI,
    },
    {
        name: 'შპს "ჯეო ჰოსპიტალს" – ხონის ამბულატორიული ცენტრი',
        address: 'ხონი, გ. ჭანტურიას ქ. N12',
        region: REGIONS.IMERETI,
    },
    {
        name: 'შპს "იმერმედი" – იმერეთის სამხარეო სამედიცინო ცენტრი (თერჯოლამედი)',
        address: 'თერჯოლა, რუსთაველის ქ.№ 82',
        region: REGIONS.IMERETI,
    },
    {
        name: 'სს ევექსი – წმინდა ნიკოლოზის სახელობის სამედიცინო ცენტრი',
        address: 'ქუთაისი, პ. იაშვილის ქ.N9ა',
        region: REGIONS.IMERETI,
    },
    {
        name: 'შპს "წყალტუბოს რაიონული საავადმყოფო"',
        address: 'წყალტუბო, ერისთავის ქ. №16',
        region: REGIONS.IMERETI,
    },
    {
        name: 'სს "საქართველოს კლინიკები" – თერჯოლის კლინიკა',
        address: 'თერჯოლა, რუსთაველის ქ.N69',
        region: REGIONS.IMERETI,
    },
    {
        name: 'სს "საქართველოს კლინიკები" – ტყიბულის კლინიკა',
        address: 'ტყიბული, თაბუკაშვილის ქ.N10',
        region: REGIONS.IMERETI,
    },
    {
        name: 'სს "საქართველოს კლინიკები" – ხონის კლინიკა',
        address: 'ხონი, სოლომონ მეორის ქ.N21',
        region: REGIONS.IMERETI,
    },
    {
        name: 'შპს "დასავლეთ საქართველოს სამედიცინო ცენტრი"',
        address: 'ქუთაისი, ჯავახიშვილის ქ. 83ა',
        region: REGIONS.IMERETI,
    },
    {
        name: 'სს "ვიანი" - კრისტინა კილის სახელობის ონკოლოგიის ცენტრი',
        address: 'ქუთაისი, ჯავახიშვილის ქ.85 / ჯავახიშვილის ქ.83ა',
        region: REGIONS.IMERETI,
    },
    {
        name: 'სს "საქართველოს კლინიკები" - ქუთაისის რეფერალური ჰოსპიტალი',
        address: 'ქუთაისი, ოცხელის ქ. #2, ნაკვეთი #2',
        region: REGIONS.IMERETI,
    },
    {
        name: 'შპს "ქუთაისის N3 ბავშვთა პოლიკლინიკა"',
        address: 'ქუთაისი, ჯავახიშვილის ქ N 85',
        region: REGIONS.IMERETI,
    },
    {
        name: 'შპს "ჯეო ჰოსპიტალს"-ის ქუთაისის ცენტრალური საავადმყოფო"',
        address: 'ქუთაისი, სოლომონ I-ის ქ. N10',
        region: REGIONS.IMERETI,
    },
    {
        name: 'შპს "ქუთაისის ახალი N2 სამშობიარო სახლი"',
        address: 'ქ. ქუთაისი, ლორთქიფანიძის ქ. #13',
        region: REGIONS.IMERETI,
    },
    {
        name: 'შპს "ქალთა ჯანმრთელობის ცენტრი ჰერა"',
        address: 'ქ. ქუთაისი, ს. მესხის 67',
        region: REGIONS.IMERETI,
    },
    {
        name: 'შპს "სამედიცინო დიაგნოსტიკური ლაბორატორია ემდილაბი"',
        address: 'ქ. ქუთაისი, ზ. გამსახურდიას ქ. 14',
        region: REGIONS.IMERETI,
    },

    {
        name: 'შპს "სპორტული მედიცინის და მეცნიერების აკადემია ქუთაისი"',
        address: 'ქ. ქუთაისი, შოთა რუსთაველის გამზირი, N104',
        region: REGIONS.IMERETI,
    },
    {
        name: 'შპს "სინევო საქართველო"',
        address:
            'ქ. ქუთაისი, ი.აბაშიძის ქ. N14; გელათის ქ. N2; ზესტაფონი, აღმაშენებლის ქ. N85; სამტრედია, ჭავჭავაძისა და ჯავახიშვილის კვეთა; ჭიათურა, ნინოშვილის ქუჩა N17',
        region: REGIONS.IMERETI,
    },
    {
        name: 'შპს "იმერმედი"-იმერეთის სამხარეო სამედიცინო ცენტრი (თერჯოლამედი)',
        address: 'თერჯოლა, რუსთაველის ქ.№ 82',
        region: REGIONS.IMERETI,
    },
    {
        name: 'შპს "ქუთაისის რეგიონალური სტომატოლოგიური ცენტრი"',
        address: 'ქუთაისი, რუსთაველის 102',
        region: REGIONS.IMERETI,
    },
    {
        name: 'შპს "როიალ-დენტალ ცენტრი"',
        address: 'ქუთაისი, თამარ მეფის ქ. N3/თბილისის ქ N2',
        region: REGIONS.IMERETI,
    },
    { name: 'შპს "დენტალ–ექსპრესი"', address: 'ქუთაისი, ბროსეს №2', region: REGIONS.IMERETI },
    { name: 'შპს "ნიუ დენტი"', address: 'ქუთაისი, პაოლო იაშვილის N9', region: REGIONS.IMERETI },
    {
        name: 'ი/მ ირინა ახობაძე - დენტ–ჰაუსი',
        address: 'სამტრედია, რუსთაველის ქუჩა N 12/23',
        region: REGIONS.IMERETI,
    },
    { name: 'ი/მ ირინე გიორგაძე', address: 'საჩხერე, დავით აღმაშემებლის 4/8', region: REGIONS.IMERETI },
    { name: 'ი/მ თეიმურაზ ძოძუაშვილი', address: 'ქუთაისი, აღმაშენებლის 35/13', region: REGIONS.IMERETI },
    { name: 'შპს "დენტალ ლუქსი"', address: 'ქ. ქუთაისი, თამარ მეფის ქ. 42', region: REGIONS.IMERETI },
    { name: 'ი/მ ნინო გრიგოლია - კლინიკა აპექსი', address: 'ვანი, თავისუფლების ქ.46', region: REGIONS.IMERETI },
    { name: 'ი/მ ნინო ნუცუბიძე - ბედნიერი ღიმილი', address: 'წყალტუბო, წერეთლის 12', region: REGIONS.IMERETI },
    { name: 'შპს "ჯანსაღი ღიმილი"', address: 'ხონი, თავისუფლების 8', region: REGIONS.IMERETI },
    { name: 'შპს "კლინიკა ელიტე"', address: 'ზესტაფონი, ასლანიკაშვილის სანაპირო', region: REGIONS.IMERETI },
    {
        name: 'ი/მ მთვარისა აბაშიძე (კლინიკა სმაილი)',
        address: 'ზესტაფონი, აღმაშენელს ქ. N57, ბ.4',
        region: REGIONS.IMERETI,
    },
    { name: 'სპს "ნინო გვალია და კომპანია"', address: 'ზესტაფონი, აღმაშენელს ქ. N57, ბ.4', region: REGIONS.IMERETI },
    { name: 'ი/მ მამუკა აბულაძე', address: 'ტყიბული, რუსთაველის ქ.N7', region: REGIONS.IMERETI },

    {
        name: 'შპს "რეგიონული ჯანდაცვის ცენტრი" – გუდაური',
        address: 'დაბა გუდაური (პოლიციის გვერდით)',
        region: REGIONS.MCKHETA_MTIANETI,
    },
    {
        name: 'შპს "ჯეო ჰოსპიტალს" – დუშეთის მრავალპროფილური სამედიცინო ცენტრი',
        address: 'დუშეთი, სტალინის 71',
        region: REGIONS.MCKHETA_MTIANETI,
    },
    {
        name: 'შპს "ჯეო ჰოსპიტალს" – მცხეთის მრავალპროფილური სამედიცინო ცენტრი',
        address: 'მცხეთა, ღვინჯილიას ქ. 5',
        region: REGIONS.MCKHETA_MTIANETI,
    },
    {
        name: 'შპს "რეგიონული ჯანდაცვის ცენტრი" – დაბა სტეფანწმინდა',
        address: 'ყაზბეგი, ყაზბეგის  N35',
        region: REGIONS.MCKHETA_MTIANETI,
    },
    {
        name: 'შპს "მცხეთის პირველადი ჯანდაცვის ცენტრი – ჯანმრთელი თაობა"',
        address: 'მცხეთა, კოსტავას 28',
        region: REGIONS.MCKHETA_MTIANETI,
    },
    {
        name: 'შპს "რეგიონული ჯანდაცვის ცენტრი" – თიანეთი',
        address: 'თიანეთი, რუსთაველის 75',
        region: REGIONS.MCKHETA_MTIANETI,
    },
    {
        name: 'შპს "სინევო საქართველო"',
        address: 'ყაზბეგი, დაბა სტეფანწმინდა (ალექსანდრე ყაზბეგის ძეგლის მოპირდაპირედ); დუშეთი, აღმაშენებლის N60',
        region: REGIONS.MCKHETA_MTIANETI,
    },

    {
        name: 'შპს "ნლი"',
        address: 'ზუგდიდი; კიტიას N 21',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'შპს "კარდიოლოგიური კლინიკა გული"',
        address: 'ზუგდიდი; ზ. გამსახურდიას N 36',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'შპს "იუნიქი" ზუგდიდი',
        address: 'ზუგდიდი, მეუნარგიას ქ.25',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'შპს "კურაციო-ზ"',
        address: 'ზუგდიდი, ზვიად გამსახურდიას გამზ. N 39',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'შპს "ბავშვთა პოლიკლინიკა"',
        address: 'ფოთი, წმინდა გიორგის 25',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'შპს "ფოთის პირველი პოლიკლინიკა"',
        address: 'ფოთი, დ. აღმაშენებლის 43, ბ. 43',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'შპს "იკამედი ფოთი"',
        address: 'ფოთი,  ერეკლე II ქ. 40',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'შპს "შანი – აბაშის საოჯახო მედიცინის ცენტრი"',
        address: 'აბაშა, თავისუფლების 92',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'ააიპ "რეაბილიტაციისა და განვითარების საქველმოქმედო ცენტრი "თანაზიარი"',
        address: 'ზუგდიდი, ზვიად გამსახურდიას 41/ა',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'შპს "ტერმინალი"',
        address: 'ზუგდიდი, ც. დადიანის ქ. N1',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'სს "ნ. მიქაიას სახელობის ჩხოროწყუს რაიონული სამშობიარო სახლი"',
        address: 'ჩხოროწყუს რაიონი, აღმაშენებლის ქ.№19',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'შპს "მესტიის საავადმყოფო-ამბულატორიული გაერთიანება"',
        address: 'დაბა მესტია, გაბლიანის ქუჩა',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'სს "ლაზიკა მედი"',
        address: 'ფოთი, ჭანტურიას ქ., № 16',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'შპს "რეგიონული ჯანდაცვის ცენტრი" – ზუგდიდის "C" ჰეპატიტის ცენტრი',
        address: 'ზუგდიდი, აღმაშენებლის 222',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'შპს "თელი წოწორიას სახ. სამედიცინო ცენტრი მკურნალი"',
        address: 'მარტვილი,  გამსახურდიას  ქ. #14',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'შპს "თელი წოწორიას სახ. სამედიცინო ცენტრი მკურნალი"',
        address: 'მარტვილი,  გამსახურდიას  ქ. #14',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'შპს "სენაკის ამბულატორიულ-პოლიკლინიკური გაერთიანება"',
        address: 'სენაკი, რუსთაველის ქ.#114',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'შპს "სენა-მედი"',
        address: 'სენაკი ჭყონდიდელის ქ #13',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'შპს "ახალი მზერა"',
        address: 'ზუგდიდი, გამსახურდიას ქ #36',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'შპს "მკურნალი სენაკის საოჯახო მედიცინის ცენტრი"',
        address: 'სენაკი, დავით ვახანიას ქ. N1',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'შპს "სამკურნალო დიაგნოსტიკური ცენტრი ესკულაპი+"',
        address: 'სენაკი ჭყონდიდელის ქ #1',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'შპს "ნეოლაბი"',
        address: 'ზუგდიდი, აღმაშენებლის ქ. N111',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'შპს სამედიცინო ცენტრი "ციტო"',
        address: 'ზუგდიდი, კიკალიშვილის 2; სენაკი, რუსთაველის 241',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'შპს "მრჩეველი-ზუგდიდი"',
        address: 'ზუგდიდი, თავისუფლების ქ. #22',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'შპს "სინევო საქართველო"',
        address: 'ზუგდიდი, კოსტავას ქ. N11ა; ფოთი, რუსთაველის რკალი 24',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'შპს "არქიმედეს კლინიკა"',
        address: 'სენაკი, რუსთაველის ქ. 110',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'სს ევექსი – ზუგდიდის პოლიკლინიკა',
        address: 'ზუგდიდი, კოსტავას ქ.N1',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'სს "საქართველოს კლინიკები" – მარტვილის კლინიკა',
        address: 'მარტვილი, მშვიდობის ქ.N111',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'სს "საქართველოს კლინიკები" – წალენჯიხის კლინიკა',
        address: 'წალენჯიხა, ჭურღულიას ქ.N6',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'სს "საქართველოს კლინიკები" – ხობის კლინიკა',
        address: 'ხობი, ჭყონდიდელის ქ.N2',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'სს "საქართველოს კლინიკები" – ჩხოროწყუს კლინიკა',
        address: 'ჩხოროწყუ, აღმაშენებლის ქ.N19',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'სს "საქართველოს კლინიკები" – აბაშის კლინიკა',
        address: 'აბაშა, თავისუფლების ქ.N143',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'სს "საქართველოს კლინიკები" - ზუგდიდის რეფერალური ჰოსპიტალი',
        address: 'ზუგდიდი, გამსახურდიას ქ. 206',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'სს "საქართველოს კლინიკები" - ფოთის ჰოსპიტალი',
        address: 'ფოთი, გურიის ქ. 171',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'შპს "ზუგდიდის სტომატოლოგიური პოლიკლინიკა"',
        address: 'ზუგდიდი, ზ. გამსახურდიას 30',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    { name: 'შპს "ნიუ დენტი"', address: 'ზუგდიდი, მ.კოსტავას N1', region: REGIONS.SAMEGRELO_QVEMO_SVANETI },
    {
        name: 'შპს "სმაილ-დენტი" (ზუგდიდი)',
        address: 'ზუგდიდი, თამარ მეფის 8',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    { name: 'შპს "ბედნიერი ღიმილი"', address: 'ზუგდიდი, გამარჯვების ქ.32', region: REGIONS.SAMEGRELO_QVEMO_SVANETI },
    { name: 'შპს "ტერმინალი"', address: 'ზუგდიდი, ც. დადიანის ქ. N1', region: REGIONS.SAMEGRELO_QVEMO_SVANETI },
    {
        name: 'ი/მ შოთა დარასელია ("როიალ+დენტი")',
        address: 'ზუგდიდი გამარჯვების ქ. N5',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    { name: 'ი/მ დარეჯანი შენგელია', address: 'სენაკი, რუსთაველის 251', region: REGIONS.SAMEGRELO_QVEMO_SVANETI },
    { name: 'შპს "დენტალ – კლასი"', address: 'ფოთი, 26 მაისის 4/16', region: REGIONS.SAMEGRELO_QVEMO_SVANETI },
    {
        name: 'ი/მ ანასტასია ხუფენია - სენადენტი',
        address: 'სენაკი, ჭავჭავაძის #80',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    {
        name: 'შპს "თელი წოწორიას სახ. სამედიცინო ცენტრი მკურნალი"',
        address: 'მარტვილი, გამსახურდიას ქ. #14',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    { name: 'ი/მ ელენე ქარჩავა', address: 'წალენჯიხა, თამარ მეფის 9', region: REGIONS.SAMEGRELO_QVEMO_SVANETI },
    {
        name: 'ი/მ ნანა ფიფია',
        address: 'მესტია, თამარ მეფის გამზ. (ფოსტის ოფისის გადასწვრივ (თამარ მეფის 8-ის პირდაპირ)',
        region: REGIONS.SAMEGRELO_QVEMO_SVANETI,
    },
    { name: 'სს "ლაზიკა მედი"', address: 'ფოთი, ჭანტურიას ქ., № 16', region: REGIONS.SAMEGRELO_QVEMO_SVANETI },

    {
        name: 'შპს "ფოთის ცენტრალური საავადმყოფო-ბომონდი"',
        address: 'ქ. ფოთი, გიორგი ვატაევის ქ. N3',
        region: REGIONS.SAMTSKHE_JAVAKHETI,
    },
    {
        name: 'შპს "რეგიონული ჯანდაცვის ცენტრი" – ბაკურიანი',
        address: 'დაბა ბაკურიანი, კ.წაქაძის ქN 2',
        region: REGIONS.SAMTSKHE_JAVAKHETI,
    },
    {
        name: 'შპს "ახალციხის კლინიკა იმედი"',
        address: 'ახალციხე, ახალქალაქის გზატკეცილი, ჩიხი 3',
        region: REGIONS.SAMTSKHE_JAVAKHETI,
    },
    {
        name: 'შპს "ნათია 777"',
        address: 'ახალციხე, თამარაშვილის 12',
        region: REGIONS.SAMTSKHE_JAVAKHETI,
    },
    {
        name: 'სს "საქართველოს კლინიკები" – ადიგენის კლინიკა',
        address: 'დაბა ადიგენი, ბალახაშვილის ქ.N11',
        region: REGIONS.SAMTSKHE_JAVAKHETI,
    },
    {
        name: 'სს "საქართველოს კლინიკები" – ნინოწმინდის კლინიკა',
        address: 'ნინოწმინდა, თავისუფლების ქ.N48',
        region: REGIONS.SAMTSKHE_JAVAKHETI,
    },
    {
        name: 'სს "საქართველოს კლინიკები" – ასპინძის კლინიკა',
        address: 'დაბა ასპინძა, შალვა ახალციხელის ქ.#1',
        region: REGIONS.SAMTSKHE_JAVAKHETI,
    },
    {
        name: 'შპს "ჯეო ჰოსპიტალს" – ბორჯომის მრავალპროფილური სამედიცინო ცენტრი',
        address: 'ბორჯომი, სააკაძის ქ. 3',
        region: REGIONS.SAMTSKHE_JAVAKHETI,
    },
    {
        name: 'სს "საქართველოს კლინიკები" - ახალქალაქის ჰოსპიტალი',
        address: 'ახალქალაქი, აღმაშენებლის ქ. 31',
        region: REGIONS.SAMTSKHE_JAVAKHETI,
    },
    {
        name: 'სს "საქართველოს კლინიკები" - ახალციხის რეფერალური ჰოსპიტალი',
        address: 'ახალციხე, რუსთაველის ქ.N105ა',
        region: REGIONS.SAMTSKHE_JAVAKHETI,
    },
    {
        name: 'შპს "ნეოლაბი"',
        address: 'ქ. ბორჯომი, რუსთაველის ქ. 23',
        region: REGIONS.SAMTSKHE_JAVAKHETI,
    },
    {
        name: 'შპს "სინევო საქართველო"',
        address: 'ბორჯომი, მესხეთის ქ.№3; ახალციხე, ნათენაძის ქ. N1',
        region: REGIONS.SAMTSKHE_JAVAKHETI,
    },
    { name: 'ი/მ არტურ ბალასანიანი', address: 'ახალციხე, მანველიშვილის 27', region: REGIONS.SAMTSKHE_JAVAKHETI },
    {
        name: 'ი/მ არმენ გრიგორიან-ხაკციან (დენტალ სერვისი)',
        address: 'ახალციხე, კოსტავას 9/11',
        region: REGIONS.SAMTSKHE_JAVAKHETI,
    },
    {
        name: 'ი/მ ალექსანდრე საანიშვილი (ალბა აპექს კლინიკ)',
        address: 'ახალციხე, კოსტავას 4/2',
        region: REGIONS.SAMTSKHE_JAVAKHETI,
    },
    {
        name: 'ი/მ ჰასმიკ მინასიან - დენტალ სტუდიო',
        address: 'ახალქალაქი, თუმანიანის 37',
        region: REGIONS.SAMTSKHE_JAVAKHETI,
    },
    { name: 'ი/მ გიორგი ჩოჩნიძე', address: 'ბორჯომი, რუსთაველის ქ.N143', region: REGIONS.SAMTSKHE_JAVAKHETI },
    { name: 'ი/მ შორენა მჭედლიძე', address: 'ბორჯომი, სააკაძის N2', region: REGIONS.SAMTSKHE_JAVAKHETI },

    {
        name: 'შპს "ჯეო ჰოსპიტალს" – გარდაბნის მრავალპროფილური სამედიცინო ცენტრი',
        address: 'გარდაბანი, ლესელიძის 1',
        region: REGIONS.QVEMO_QARTLI,
    },
    {
        name: 'შპს "ავერსის კლინიკა" მარნეულის №1 ფილიალი',
        address: 'მარნეული, 26 მაისის ქუჩა',
        region: REGIONS.QVEMO_QARTLI,
    },
    {
        name: 'შპს "ავერსის კლინიკა" მარნეულის №2 ფილიალი',
        address: 'მარნეული, სულხან–საბას 58',
        region: REGIONS.QVEMO_QARTLI,
    },
    {
        name: 'შპს "ჯეო ჰოსპიტალს" – მარნეულის მრავალპროფილური სამედიცინო ცენტრი',
        address: 'მარნეული, ყოფ. სამხედრო ქალაქის ტერიტორია',
        region: REGIONS.QVEMO_QARTLI,
    },
    {
        name: 'შპს "ავერსის კლინიკა" რუსთავის ფილიალი',
        address: 'რუსთავი, მესხიშვილის ქ. 3ა',
        region: REGIONS.QVEMO_QARTLI,
    },
    {
        name: 'შპს "კლინიკა რუსთავი"',
        address: 'რუსთავი, VII მკ/რ',
        region: REGIONS.QVEMO_QARTLI,
    },
    {
        name: 'სს "რუსთავის ბავშვთა საავადმყოფო"',
        address: 'რუსთავი, წმინდა ნინოს 5',
        region: REGIONS.QVEMO_QARTLI,
    },
    {
        name: 'სს "რუსთავის სამშობიარო სახლი"',
        address: 'რუსთავი, წმინდა ნინოს #3',
        region: REGIONS.QVEMO_QARTLI,
    },
    {
        name: 'სს "რუსთავის ცენტრალური საავადმყოფო"',
        address: 'რუსთავი, წმ. ნინოს 3',
        region: REGIONS.QVEMO_QARTLI,
    },
    {
        name: 'შპს "მარდალეიშვილის სამედიცინო ცენტრი-რუსთავი"',
        address: 'რუსთავი, გაგარინის 12',
        region: REGIONS.QVEMO_QARTLI,
    },
    {
        name: 'შპს "ჯეო ჰოსპიტალს" – მარნეულის სამშობიარო სახლი და ამბულატორიული სამედიცინო ცენტრი',
        address: 'მარნეული, რუსთაველის ქ. №112',
        region: REGIONS.QVEMO_QARTLI,
    },
    {
        name: 'შპს "ბოლნისის ცენტრალური კლინიკა"',
        address: 'ბოლნისი, დ.აღმაშენებლის ქ.№25',
        region: REGIONS.QVEMO_QARTLI,
    },
    {
        name: 'სს "რუსთავის №2 სამკურნალო-დიაგნოსტიკური ცენტრი"',
        address: 'რუსთავი, მესხიშვილის 1ა',
        region: REGIONS.QVEMO_QARTLI,
    },
    {
        name: 'შპს "სინევო საქართველო"',
        address:
            'რუსთავი, გ.ლეონიძის და ვ. მესხიშვილის ქუჩების გადაკვეთა; მეგობრობის გამზ. N8; მარნეული, 26 მაისის ქ. N80; გარდაბანი, სოფ. სართიჭალა, ნომერი პირველი ქ. N41',
        region: REGIONS.QVEMO_QARTLI,
    },
    {
        name: 'შპს "რეგიონული ჯანდაცვის ცენტრი" – წალკა',
        address: 'წალკა, ექ. თაყაიშვილის ქ. N4',
        region: REGIONS.QVEMO_QARTLI,
    },
    {
        name: 'შპს "რეგიონული ჯანდაცვის ცენტრი" – თეთრიწყარო',
        address: 'დაბა თეთრიწყარო, რუსთაველის ქ.',
        region: REGIONS.QVEMO_QARTLI,
    },
    {
        name: 'შპს "რეგიონული ჯანდაცვის ცენტრი" –  – დმანისის სამედიცინო დაწესებულება',
        address: '	დმანისი, წმ. ნინოს ქ. N37',
        region: REGIONS.QVEMO_QARTLI,
    },
    {
        name: 'შპს "ავერსის კლინიკა" მარნეულის №2 ფილიალი',
        address: 'მარნეული, სულხან–საბას 58',
        region: REGIONS.QVEMO_QARTLI,
    },
    {
        name: 'შპს "ავერსის კლინიკა" რუსთავის ფილიალი',
        address: 'რუსთავი, მესხიშვილის 3/ა',
        region: REGIONS.QVEMO_QARTLI,
    },
    { name: 'შპს "დენსი"', address: 'რუსთავი, კოსტავას 11; რუსთავი, შარტავას 17', region: REGIONS.QVEMO_QARTLI },
    {
        name: 'შპს "ლია ბაწელაშვილის სტომატოლოგიური ჰაბი ვაით ლაინი"',
        address: 'ქ. რუსთავი, შარტავას გამზ. 2გ (მე-2 სართ.)',
        region: REGIONS.QVEMO_QARTLI,
    },
    {
        name: 'ი/მ გიორგი მარტიაშვილი - ბოლნისი დენტალი',
        address: 'ბოლნისი, სულხან-საბა ორბელიანის ქ.N141',
        region: REGIONS.QVEMO_QARTLI,
    },
    { name: 'შპს "გლობალმედ"', address: 'ბოლნისი, სულხან-საბა ორბელიანის 72', region: REGIONS.QVEMO_QARTLI },
    { name: 'შპს "დენსი"-გარდაბანი', address: 'გარდაბანი, აღმაშენებლის ქ., №46', region: REGIONS.QVEMO_QARTLI },

    {
        name: 'შპს "ახალი მზერა"',
        address: 'მარნეული, 26 მაისის ქ.#80',
        region: REGIONS.SHIDA_QARTLI,
    },
    {
        name: 'შპს "მედალფა" კასპის კლინიკა',
        address: 'კასპი, სააკაძის 27/ბ',
        region: REGIONS.SHIDA_QARTLI,
    },
    {
        name: 'შპს "ავერსის კლინიკა" გორი №1',
        address: 'გორი, ცხინვალის გზატკეცილი 12',
        region: REGIONS.SHIDA_QARTLI,
    },
    {
        name: 'შპს "ავერსის კლინიკა" გორი №2',
        address: 'გორი, სამეფოს 78',
        region: REGIONS.SHIDA_QARTLI,
    },
    {
        name: 'შპს "ჯუმბერ გოგიაშვილის კლინიკა"',
        address: 'გორი, ცხინვალის გზატ. 14',
        region: REGIONS.SHIDA_QARTLI,
    },
    {
        name: 'შპს "ახალი კლინიკა" -ხაშურის ჰოსპიტალი',
        address: 'ხაშური, რუსთაველის ქ. #40',
        region: REGIONS.SHIDA_QARTLI,
    },
    {
        name: 'შპს "დასტაქარი"',
        address: 'ქარელი, რუსთაველის 4',
        region: REGIONS.SHIDA_QARTLI,
    },
    {
        name: 'შპს "გორმედი"',
        address: 'გორი, ცხინვალის გზატ. 14ა',
        region: REGIONS.SHIDA_QARTLI,
    },
    {
        name: 'შპს "გორმედი"',
        address: 'ქარელი, სოფ. ბებნისი',
        region: REGIONS.SHIDA_QARTLI,
    },
    {
        name: 'შპს "გორმედი"',
        address: 'ხაშური, ფარნავაზის 5',
        region: REGIONS.SHIDA_QARTLI,
    },
    {
        name: 'შპს სამედიცინო ცენტრი "ციტო"',
        address: 'გორი, სტალინის ქ. 48',
        region: REGIONS.SHIDA_QARTLI,
    },
    {
        name: 'შპს "ალიანს მედი" – ქარელის კლინიკა',
        address: 'ქარელი, ფანასკერტელის ქ.N30',
        region: REGIONS.SHIDA_QARTLI,
    },
    {
        name: 'შპს "სინევო საქართველო"',
        address: 'გორი, ჭავჭავაძის ქ. N52; ხაშური, რუსთაველის ქ. N60; ქარელი, თამარ მეფის ქ. N56',
        region: REGIONS.SHIDA_QARTLI,
    },
    {
        name: 'შპს "ავერსის კლინიკა" გორი №1',
        address: 'გორი, ცხინვალის გზატკეცილი 12',
        region: REGIONS.SHIDA_QARTLI,
    },
    { name: 'ი/მ მაია რაზმაძე MS Dent', address: 'გორი, ხერხეულიძის 25', region: REGIONS.SHIDA_QARTLI },
    { name: 'შპს "მარიმედი"', address: 'კასპი, გიორგი სააკაძის 110', region: REGIONS.SHIDA_QARTLI },
    { name: 'ი/მ ნინო ბარბაქაძე', address: 'ხაშური, ლესელიძის N 7', region: REGIONS.SHIDA_QARTLI },
    { name: 'ი/მ შორენა ხუციშვილი - ელიტ მედი', address: 'კასპი, კოსტავას 5', region: REGIONS.SHIDA_QARTLI },

    {
        name: 'შპს "ბათუმის სამედიცინო ცენტრი"',
        address: 'ბათუმი, კახაბერის ქ. N40',
        region: REGIONS.ADJARA,
    },
    { name: 'შპს "ბათუმის სამედიცინო ცენტრი"', address: 'ბათუმი, კახაბერის ქ. N40', region: REGIONS.ADJARA },
    {
        name: 'შპს "მაღალტექნოლოგიური ჰოსპიტალი მედცენტრი"',
        address: 'ბათუმი, პუშკინის ქუჩა N118/120',
        region: REGIONS.ADJARA,
    },
    {
        name: 'შპს "საგიტარიუსი მეზღვაურთა სამედიცინო ცენტრი"',
        address: 'ბათუმი, ზურაბ გორგილაძის 91',
        region: REGIONS.ADJARA,
    },
    {
        name: 'შპს "დავით ტატიშვილის სამედიცინო ცენტრი - ბათუმი"',
        address: 'ბათუმი, პ.ბაგრატიონის ქ.N129, კომერციული ფართი N5, სართ 2, ბლოკი "ა"',
        region: REGIONS.ADJARA,
    },
    {
        name: 'შპს აკ. ვ. ივერიელის სახელობის ენდოკრინოლოგია, მეტაბოლოგია, დიეტოლოგიის ცენტრი "ენმედიცი"',
        address: 'ბათუმი, ჯავახიშვილის ქ. 3ა',
        region: REGIONS.ADJARA,
    },
    { name: 'შპს "ემ-ემ-ტე ჰოსპიტალი"', address: 'ბათუმი, რუსთაველის ქ. N35', region: REGIONS.ADJARA },
    { name: 'შპს "იუნიქი" ბათუმი', address: 'ბათუმი, ს. ხიმშიაშვილის ქ. 62', region: REGIONS.ADJARA },
    { name: 'შპს "საოჯახო მედიცინის რეგიონული ცენტრი"', address: 'ბათუმი, ბარათაშვილის 33', region: REGIONS.ADJARA },
    {
        name: 'შპს "ინოვაციური სტომატოლოგიისა და ზოგადი მედიცინის ცენტრი როგო"',
        address: 'ბათუმი, ვ.გორგასალის ქ. 163',
        region: REGIONS.ADJARA,
    },
    { name: 'შპს "ნეოლაბი"', address: 'ბათუმი, ჭავჭავაძის გამზ. 70', region: REGIONS.ADJARA },
    {
        name: 'შპს "ქალაქ ბათუმის მრავალპროფილიანი სამშობიარო სახლი"',
        address: 'ბათუმი, რუსთაველის 39',
        region: REGIONS.ADJARA,
    },
    {
        name: 'შპს "მახინჯაურის მრავალპროფილიანი პოლიკლინიკა"',
        address: 'ბათუმი, თამარ მეფის გამზ. III შესახვევი #17',
        region: REGIONS.ADJARA,
    },
    { name: 'სს "ქობულეთის სამედიცინო ცენტრი"', address: 'ქობულეთი, თბილისის ქ. 31', region: REGIONS.ADJARA },
    { name: 'შპს სამედიცინო ცენტრი "ციტო"', address: 'ბათუმი, ფარნავაზ მეფის N107', region: REGIONS.ADJARA },
    { name: 'შპს "მრჩეველი-ბათუმი"', address: 'ბათუმი, ბაგრატიონის 190/192', region: REGIONS.ADJARA },
    {
        name: 'შპს "სინევო საქართველო"',
        address:
            'ბათუმი, რუსთაველის ქ. N41, გრიბოედოვის ქ N5; ანგისის ქუჩა N2 (ვოქსის შენობა); ქობულეთი, თავისუფლების ქ. N5',
        region: REGIONS.ADJARA,
    },
    { name: 'სს "მეზღვაურთა სამედიცინო ცენტრი 2010"', address: 'ბათუმი, ტაბიძის 2/ა', region: REGIONS.ADJARA },
    {
        name: 'სს "კ. ერისთავის სახელობის ექსპერიმენტული და კლინიკური ქირურგიის ეროვნული ცენტრი" - ბათუმი',
        address: 'ბათუმი, გოგოლის შესახვევი N2.',
        region: REGIONS.ADJARA,
    },
    { name: 'სს ევექსი – ბათუმის პოლიკლინიკა', address: 'ბათუმი, ხიმშიაშვილის ქ.N20', region: REGIONS.ADJARA },
    {
        name: 'სს ევექსი – ჩაქვის პოლიკლინიკა',
        address: 'დაბა ჩაქვი, თამარ მეფის ქ.N40–ის მიმდებარედ',
        region: REGIONS.ADJARA,
    },
    {
        name: 'სს "საქართველოს კლინიკები" – ქედის კლინიკა',
        address: 'დაბა ქედა, რუსთაველის ქ.N14',
        region: REGIONS.ADJARA,
    },
    {
        name: 'სს "საქართველოს კლინიკები" – შუახევის კლინიკა',
        address: 'დაბა შუახევი, რუსთაველის ქ.N26',
        region: REGIONS.ADJARA,
    },
    {
        name: 'სს "საქართველოს კლინიკები" – ხულოს კლინიკა',
        address: 'დაბა ხულო, აღმაშენებლის ქ.N1',
        region: REGIONS.ADJARA,
    },
    {
        name: 'შპს "მაღალტექნოლოგიური ჰოსპიტალი მედცენტრი" - ბათუმის რეფერალური ჰოსპიტალი',
        address: 'დაბა ხულო, აღმაშენებლის ქ.N1',
        region: REGIONS.ADJARA,
    },
    {
        name: 'სს "ვიანი" – მ. იაშვილის სახელობის ბათუმის დედათა და ბავშვთა ცენტრალური ჰოსპიტალი',
        address: 'ბათუმი, ბაგრატიონის ქ. 125',
        region: REGIONS.ADJARA,
    },
    {
        name: 'სს "საქართველოს კლინიკები" - ქობულეთის ჰოსპიტალი',
        address: 'ბათუმი, აეროპორტის გზატკეცილი, 64',
        region: REGIONS.ADJARA,
    },
    { name: 'შპს "ნიუ დენტი"', address: 'ბათუმი, ხიმშიაშვილის N20', region: REGIONS.ADJARA },
    { name: 'შპს "სტომა დენტი"', address: 'ბათუმი, მელაშვილის 24 ბ.1', region: REGIONS.ADJARA },
    {
        name: 'შპს "დენტალ–არტი"',
        address: 'ბათუმი, ლერმონტოვის 94; ბათუმი, ს.ხიმშიაშვილის 33',
        region: REGIONS.ADJARA,
    },
    { name: 'შპს "დენტალ ლაუნჯი" (დავიდ დენტი)', address: 'ბათუმი, ნურეთინ ჩარმიქლის 5', region: REGIONS.ADJARA },
    { name: 'შპს Elite-dent+', address: 'ქობულეთი, დ.აღმაშენებლის გამზ., N 152', region: REGIONS.ADJARA },
    { name: 'შპს "სმაილ გალერი"', address: 'ბათუმი, თავდადებულის ქ., N28', region: REGIONS.ADJARA },
    {
        name: 'შპს "დენტა-ლუქსი"',
        address:
            'ბათუმი, ახმეტელის N1 ბ.2; ბათუმი, ფარნავაზ მეფის N140; ბათუმი, ჯავახიშვილის N76 4.ბათუმი, ზურაბ გორგილაძის 69',
        region: REGIONS.ADJARA,
    },
    {
        name: 'შპს "ინოვაციური სტომატოლოგიისა და ზოგადი მედიცინის ცენტრი როგო"',
        address: 'ქ. ბათუმი, ვ.გორგასალის ქ. 163',
        region: REGIONS.ADJARA,
    },

    { name: 'ი/მ ეკა კუკულაძე', address: 'ლანჩხუთი, ჟორდანიას 128', region: REGIONS.GURIA },
    { name: 'ი/მ მაია ტრაპაიძე', address: 'ჩოხატაური, დუმბაძის 5', region: REGIONS.GURIA },
    { name: 'ი/მ მანანა ზაუტაშვილი (მეფა-დენტი)', address: 'ჩოხატაური, დუმბაძის 24', region: REGIONS.GURIA },
    { name: 'შპს "სმაილ-დენტი" (ოზურგეთი)', address: 'ოზურგეთი, ა. წულაძის 10', region: REGIONS.GURIA },
    { name: 'შპს მედალფა ოზურგეთის კლინიკა', address: 'ოზურგეთი, ნინოშვილის 3', region: REGIONS.GURIA },
    { name: 'შპს მედალფა ლანჩხუთის კლინიკა', address: 'ლანჩხუთი, ჟორდანიას 136', region: REGIONS.GURIA },
    { name: 'შპს მედალფა ჩოხატაურის კლინიკა', address: 'ჩოხატაური, თბილისის 10', region: REGIONS.GURIA },
    {
        name: 'შპს რეგიონული ჯანდაცვის ცენტრი - ლანჩხუთი',
        address: 'ლანჩხუთი, ჭანტურიას 21',
        region: REGIONS.GURIA,
    },
    { name: 'შპს სინევო საქართველო', address: 'ოზურგეთი, ჭავჭავაძის ქუჩა N2', region: REGIONS.GURIA },
]

export const propertyBoxes = [
    { title: 'შენობა-ნაგებობა', icon: 'buildings' },
    { title: 'მანქანა-დანადგარები', icon: 'machine' },
    { title: 'ტექნიკა', icon: 'technic' },
    { title: 'მარაგები', icon: 'stocks' },
    { title: 'შიგთავსი', icon: 'contents' },
    { title: 'ვიტრაჟები', icon: 'stained-glass' },
]

export const propertyList = [
    { content: 'ხანძარი, აფეთქება, მეხის დაცემა' },
    {
        content:
            'სტიქიური მოვლენები: შტორმი, ქარიშხალი, გრიგალი, წყალდიდობა, ღვარცოფი, უხვთოვლიანობა, სეტყვა, მიწისძვრა, მეწყერი, ზვავი, გრუნტის ჩამოშლა',
    },
    { content: 'დატბორვა, წყლით დაზიანება' },
    { content: 'ქურდობა, ძარცვა, ყაჩაღობა, ვანდალიზმი' },
    { content: 'სატრანსპორტო საშუალების შეჯახება' },
    { content: 'მინების მსხვრევა' },
]

export const responsibilityList = [
    { content: 'გადამზიდავის (ექსპედიტორის) პასუხისმგებლობის დაზღვევა' },
    {
        content: 'სამოქალაქო პასუხისმგებლობის დაზღვევა',
    },
    { content: 'პროფესიული პასუხიმგებლობის დაზღვევა' },
    { content: 'კიბერუსაფრთხოების პასუხისმგებლობის დაზღვევა' },
    { content: 'მასობრივი თავშეყრის ობიექტის მფლობელის მიერ მესამე პირის წინაშე პასუხისმგებლობის დაზღვევა' },
    { content: 'ექიმების პასუხისმგებლობის დაზღვევა' },
    { content: 'დირექტორების და დამფუძნებლების პასუხისმგებლობის დაზღვევა' },
    { content: 'დამქირავებლის პასუხისმგებლობის დაზღვევა' },
]

export const transportList = [
    {
        content: 'ავტომობილის ძარის დაზღვევა',
        answer: [
            'ავტოსაგზაო შემთხვევა',
            'ხანძარი, აფეთქება, მეხის დაცემა',
            'ქურდობა, ძარცვა, ყაჩაღობა, ვანდალიზმი',
            'სტიქიური მოვლენები',
            'სხვადასხვა საგნების დაცემა',
            'გარე დეტალების, ქარხნული აქსესუარების, მინების, სარკეების ქურდობა-დაზიანება',
        ],
    },
    {
        content: 'მესამე პირის წინაშე პასუხისმგებლობის დაზღვევაა',
    },
    { content: 'მძღოლისა და მგზავრების დაზღვევა' },
    {
        content: 'სპეც. ტექნიკის მუშაობის პროცესში დაზღვევა',
        answer: [
            'შეჯახება, გადაბრუნება',
            'ხანძარი, აფეთქება, მეხის დაცემა',
            'სტიქიური მოვლენები',
            'ქურდობა, ძარცვა, ყაჩაღობა',
            'საგნების დაცემა',
        ],
    },
    {
        content: 'როგორ მოვიქცე ავტოსაგზაო შემთხვევის დადგომისას?',
        answer: 'შეინარჩუნეთ ავტოსაგზაო შემთხვევის პირვანდელი მდგომარეობა დაუკავშირდით ჩვენს ცხელ ხაზს 2 640 640 (საჭიროების შემთხვევაში, დაუკავშირდით საპატრულო პოლიციას)',
    },
]

export const cargoList = [
    { content: 'სატრანსპორტო ტვირთები' },
    { content: 'სარკინიგზო ტვირთები' },
    { content: 'საზღვაო ტვირთები' },
    { content: 'საჰაერო ტვირთები' },
]

export const travelList = [
    { content: 'სასწრაფო სამედიცინო მომსახურება' },
    { content: 'გადაუდებელი ამბულატორიული მომსახურება' },
    { content: 'გადაუდებელი ჰოსპიტალური მომსახურება' },
    { content: 'გადაუდებელი სტომატოლოგიური მომსახურება ჰოსპიტალური მომსახურება' },
    { content: 'გადაუდებელი ოფთალმოლოგიური მომსახურება' },
    { content: 'დაზღვეულის (დაშავებულის, ავადმყოფის) რეპატრიაცია' },
]

export const cyberList = [
    {
        content:
            'პასუხისმგებლობა პირად ინფორმაციაზე - მზღვეველი პასუხისმგებელია აანაზღაუროს ზარალი, სადაც ფიქსირდება დაზღვეულის პასუხისმგებლობა პირადი ინფორმაციის გაჟონვასთან დაკავშირებით.',
    },
    {
        content:
            'პასუხისმგებლობა კორპორატიულ ინფორმაციაზე - მზღვეველი პასუხისმგებელია აანაზღაუროს ზარალი, სადაც ფიქსირდება დაზღვეულის პასუხისმგებლობა კორპორატიული ინფორმაციის გაჟონვასთან დაკავშირებით.',
    },
    {
        content:
            'პასუხისმგებლობა აუთსორსინგზე - მზღვეველი პასუხისმგებელია აანაზღაუროს ზარალი, სადაც ფიქსირდება დაზღვეულის პასუხისმგებლობა პირადი ან კორპორატიული ინფორმაციის გაჟონვასთან დაკავშირებით და აღნიშნულზე პასუხისმგებელია დაზღვეულის აუთსორსერი.',
    },
    {
        content: 'პასუხისმგებლობა ინფორმაციის უსაფრთხოებაზე - ანაზღაურდება ზარალი, რომელიც გამოწვეულია:',
        answer: [
            'არაავტორიზირებული პროგრამის, კომპიუტერული კოდის ან ვირუსის მიერ კორპორატიული ან მესამე მხარის ნფორმაციის დაბინძურებით/დაზიანებით;',
            'ავტორიზირებული მესამე პირისათვის კორპორატიულ ან სხვა ინფორმაციაზე წვდომის არასწორი ჩაკეტვით;',
            'დაზღვეულის სამუშაო სივრციდან წვდომის კოდის ქურდობით;',
            'დაზღვეულის უსაფრთხოების სისტემაში შეღწევის შედეგად ინფორმაციის განადგურებით, მოდიფიკაციით, დაზიანებით;',
            'დაზღვეულის უსაფრთხოების სისტემაში შეღწევის შედეგად გამოწვეული არაავტორიზირებული სარგებლობით ან დამაზიანებელი კოდის გავრცელებით;',
        ],
        showAll: true,
    },
]

export const benefitsList = [
    { content: 'პირადი მენეჯერი ყველა თანამშრომლისთვის', urlString: 'პირადი მენეჯერი', url: '/private-managers' },
    {
        content:
            'ოჯახის ექიმის მომსახურება თბილისის ყველა უბანში და საქართველოს უმეტეს რეგიონში, როგორც სამუშაო დღეებში, ასევე შაბათ–კვირას',
    },
    { content: 'დამატებით 5% ფასდაკლება ნებისმიერ  შენაძენზე ავერსის აფთიაქებში' },
    {
        content:
            'პირადი ექიმის მიერ გამოწერილ მედიკამენტებზე, ავერსის აფთიაქში ფასდაკლების დღეებში თანაგადახდა დაანგარიშდება ფასდაკლებული თანხიდან და რეალურად სარგებლობთ ორმაგი ფასდაკლებით',
    },
    { content: 'ამბულატორიული მკურნალობა და მედიკამენტები საზღვარგარეთ' },
    { content: 'ინვიტრო განაყოფიერება – დაფინანსება 40%' },
    {
        content:
            'ფასდაკლებები და შეთავაზებები პარტნიორ კომპანიებში, როგორიცაა: სარეაბილიტაციო, სპორტულ–გამაჯანსაღებელი, ესთეტიკური, ფიზიოთერაპიური და მენტალური ჯანმრთელობის ცენტრები',
    },
    { content: 'მომსახურების მიღება გამარტივებულად და კომფორტულად' },
    {
        content:
            'დისტანციური სერვისი, ოჯახის ექიმის ან პირადი მენეჯერის დახმარებით, რაშიც ზოგავთ დროს, ენერგიას და იღებთ ხარისხიან მომსახურებას',
    },
    { content: 'ამბულატორიული და ჰოსპიტალური მომსახურება საზღვარგარეთ' },
]

export const districts = [
    'ვაკე',
    'საბურთალო',
    'მთაწმინდა',
    'გლდანი',
    'ისანი',
    'დიდუბე',
    'ნაძალადევი',
    'სამგორი',
    'კრწანისი',
    'ჩუღურეთი',
    'რეგიონები',
]

export const doctors = [
    {
        doctor: 'ალექსანდრე ღლიღვაშვილი',
        address: 'ყაზბეგის გამზირი # 16',
        district: 'საბურთალო',
        city: REGIONS.TBILISI,
    },
    {
        city: REGIONS.TBILISI,
        district: 'საბურთალო',
        address: 'ყაზბეგის გამზირი # 16',
        doctor: 'ნანა ბიბილური',
    },
    {
        city: REGIONS.TBILISI,
        district: 'ისანი',
        address: 'ნავთლუღის ქ 6/2',
        doctor: 'ნინა კვირიკაშვილი',
    },
    {
        city: REGIONS.TBILISI,
        district: 'საბურთალო',
        address: 'ვაჟა ფშაველას 27 ბ',
        doctor: 'ნანა ქავთარაძე',
    },
    {
        city: REGIONS.TBILISI,
        district: 'საბურთალო',
        address: 'ვაჟა ფშაველას 27 ბ',
        doctor: 'რუსუდან ლორთქიფანიძე',
    },
    {
        city: REGIONS.TBILISI,
        district: 'საბურთალო',
        address: 'გამრეკელის ქ. #19',
        doctor: 'თამარ ბარაბაძე',
    },
    {
        city: REGIONS.TBILISI,
        district: 'გლდანი',
        address: 'ვეკუას # 18',
        doctor: 'მარინა მაისურაძე',
    },
    {
        city: REGIONS.TBILISI,
        district: 'ვაკე',
        address: 'აბულაძის ქ. #2',
        doctor: 'ნინო კანდელაკი',
    },
    {
        city: REGIONS.TBILISI,
        district: 'საბურთალო',
        address: 'ვაჟა ფშაველას 76 ბ',
        doctor: 'მანანა დუგლაძე',
    },
    {
        city: REGIONS.TBILISI,
        district: 'საბურთალო',
        address: 'სანდროეულის #7',
        doctor: 'სოფო თულაშვილი',
    },
    {
        city: REGIONS.TBILISI,
        district: 'ჩუღურეთი',
        address: 'კოტე მარჯანიშვილის ქუჩა #9',
        doctor: 'მარინა საბაშვილი',
    },
    {
        city: REGIONS.TBILISI,
        district: 'ჩუღურეთი',
        address: 'კოტე მარჯანიშვილის ქუჩა #10',
        doctor: 'მაია გუშარაშვილი',
    },
    {
        city: REGIONS.TBILISI,
        district: 'დიდუბე',
        address: 'წერეთლის გამზ. # 138',
        doctor: 'თამარ ლომიძე',
    },
    {
        city: REGIONS.TBILISI,
        district: 'დიდუბე',
        address: 'წერეთლის გამზ.# 138',
        doctor: 'ეკა შალიკაშვილი',
    },
    {
        city: REGIONS.TBILISI,
        district: 'საბურთალო',
        address: 'ი.პეტრიწის N16',
        doctor: 'ლელა პაპუაშვილი',
    },
    {
        title: true,
        city: 'ახალციხე',
        district: REGIONS.SAMTSKHE_JAVAKHETI,
        address: 'ახალქალაქის გზატკეცილი #3 ჩიხი',
        doctor: 'ნარინე კავალერიან',
    },
    {
        city: 'ახალქალაქი',
        district: REGIONS.SAMTSKHE_JAVAKHETI,
        address: 'დ. აღმაშენებლის ქ.31',
        doctor: 'მზია კოჩალიძე',
    },
    {
        city: 'ბორჯომი',
        district: REGIONS.SAMTSKHE_JAVAKHETI,
        address: 'სააკაძის ქ. 3',
        doctor: 'მაია ლაბაძე',
    },
    {
        title: true,
        city: 'მცხეთა',
        district: REGIONS.MCKHETA_MTIANETI,
        address: 'კოსტავას #28',
        doctor: 'ნინო კოზმანიშვილი',
    },
    {
        city: 'სტეფანწმინდა',
        district: REGIONS.MCKHETA_MTIANETI,
        address: 'ალექსანდრე ყაზბეგის # 35',
        doctor: 'მაია თევზაძე',
    },
    {
        title: true,
        city: 'გორი',
        district: REGIONS.SHIDA_QARTLI,
        address: 'სამეფოს 78',
        doctor: 'ლელა გუზიტაშვილი',
    },
    {
        city: 'კასპი',
        district: REGIONS.SHIDA_QARTLI,
        address: 'სააკაძის ქ. N 27ბ',
        doctor: 'თენგიზ მამაცაშვილი',
    },
    {
        title: true,
        city: 'ქუთაისი',
        district: REGIONS.IMERETI,
        address: 'რუსთაველის #112',
        doctor: 'ნინო მახარაძე',
    },
    {
        city: 'ქუთაისი',
        district: REGIONS.IMERETI,
        address: 'თამარ მეფის # 5/8',
        doctor: 'ხათუნა ზაქარაია',
    },
    {
        city: 'ზესტაფონი',
        district: REGIONS.IMERETI,
        address: 'მაღლაკელიძის# 4',
        doctor: 'ნინო ჭანკვეტაძე',
    },
    {
        city: 'სამტრედია',
        district: REGIONS.IMERETI,
        address: 'ჭანტურიას #2',
        doctor: 'ანა კოხრეიძე',
    },
    {
        city: 'საჩხერე',
        district: REGIONS.IMERETI,
        address: 'საჩხერე, გომართელის 17',
        doctor: 'დარეჯან გულიაშვილი',
    },
    {
        title: true,
        city: 'ბათუმი',
        district: REGIONS.ADJARA,
        address: 'ჯავახიშვილის 3ა',
        doctor: 'ქეთევან ჯაყელი',
    },
    {
        city: 'ბათუმი',
        district: REGIONS.ADJARA,
        address: 'რუსთაველის გამზირი 35',
        doctor: 'ქეთევან ჯაყელი',
    },
    {
        city: 'ბათუმი',
        district: REGIONS.ADJARA,
        address: 'ბათუმი, ხიმშიაშვილის ქ.N20',
        doctor: 'რუსუდან ცისკარიძე',
    },
    {
        city: 'ქობულეთი',
        district: REGIONS.ADJARA,
        address: 'თბილისის ქ 31',
        doctor: 'ნათელა გოგიტიძე',
    },
    {
        title: true,
        city: 'ფოთი',
        district: 'სამეგრელო',
        address: 'ჭანტურიას ქ16',
        doctor: 'ნინო სიმონია',
    },
    {
        city: 'ზუგდიდი',
        district: 'სამეგრელო',
        address: 'მეუნარგიას ქ.25',
        doctor: 'მარინა ჯიშკარიანი',
    },
    {
        title: true,
        city: 'რუსთავი',
        district: REGIONS.QVEMO_QARTLI,
        address: 'მესხიშვილის №3ა',
        doctor: 'თეა ქებაძე',
    },
    {
        city: 'რუსთავი',
        district: REGIONS.QVEMO_QARTLI,
        address: 'მესხიშვილის №3ა',
        doctor: 'ეკა გელაშვილი',
    },
    {
        city: 'მარნეული',
        district: REGIONS.QVEMO_QARTLI,
        address: 'სულხან–საბას №58',
        doctor: 'გივი ყურაშვილი',
    },
    {
        title: true,
        city: 'ოზურგეთი',
        district: REGIONS.GURIA,
        address: 'ჟღენტის # 4',
        doctor: 'ლალი ხინთიბიძე',
    },
    {
        city: 'ლანჩხუთი',
        district: REGIONS.GURIA,
        address: 'ჟორდანიას #130',
        doctor: 'ვერიკო ჩიჩუა',
    },
    {
        city: 'ჩოხატაური',
        district: REGIONS.GURIA,
        address: 'თბილისის 10',
        doctor: 'ელისო სიხარულიძე',
    },
    {
        title: true,
        city: 'დედოფლისწყარო',
        district: REGIONS.KAKHETI,
        address: 'ნატროშვილი ანტონის ქ#2',
        doctor: 'მანანა ბოსტოღანაშვილი',
    },
    {
        city: 'ლაგოდეხი',
        district: REGIONS.KAKHETI,
        address: 'ნინოშვილის ქ. N 2',
        doctor: 'ნონა ბუაძე',
    },
    {
        city: 'ახმეტა',
        district: REGIONS.KAKHETI,
        address: 'ახმეტა, რუსთაველის ქ.N78ა',
        doctor: 'ლალი ნონიაშვილი',
    },
    {
        city: 'გურჯაანი',
        district: REGIONS.KAKHETI,
        address: 'რუსთაველის #22',
        doctor: 'ეკა ლიპარტაშვილი',
    },
    {
        city: 'თელავი',
        district: REGIONS.KAKHETI,
        address: 'ალადაშვილის # 2',
        doctor: 'ლალი კევლიშვილი',
    },
    {
        title: true,
        city: 'ონი',
        district: 'რაჭა',
        address: 'ონი, ვახტანგ მე– 6 ქ N10',
        doctor: 'თამარ გრძელიშვილი',
    },
]
