import { FC } from 'react'
import styled from '@emotion/styled'

import { ReactComponent as IconPhone } from 'assets/icons/phone.svg'

import { ReactComponent as IconHealth } from 'assets/icons/health.svg'
import { ReactComponent as IconProperty } from 'assets/icons/property.svg'
import { ReactComponent as IconResponsibility } from 'assets/icons/responsibility.svg'
import { ReactComponent as IconTransport } from 'assets/icons/transport.svg'
import { ReactComponent as IconCargo } from 'assets/icons/cargo.svg'
import { ReactComponent as IconTravel } from 'assets/icons/travel.svg'
import { ReactComponent as IconCyber } from 'assets/icons/cyber.svg'
import { ReactComponent as IconClinics } from 'assets/icons/clinics.svg'
import { ReactComponent as IconDoctors } from 'assets/icons/doctors.svg'
import { ReactComponent as IconBenefit } from 'assets/icons/benefit.svg'

import { ReactComponent as IconPrivateManagers } from 'assets/icons/private-managers.svg'
import { ReactComponent as IconBuildings } from 'assets/icons/buildings.svg'
import { ReactComponent as IconMachine } from 'assets/icons/machine.svg'
import { ReactComponent as IconTechnic } from 'assets/icons/technic.svg'
import { ReactComponent as IconStocks } from 'assets/icons/stocks.svg'
import { ReactComponent as IconContents } from 'assets/icons/contents.svg'
import { ReactComponent as IconStainedGlass } from 'assets/icons/stained-glass.svg'
import { ReactComponent as IconServiceCenters } from 'assets/icons/service-centers.svg'
import { ReactComponent as IconBackAutoAssistance } from 'assets/icons/auto-assistance.svg'

const getIcon = (iconName: string) => {
    switch (iconName) {
        case 'phone':
            return IconPhone
        case 'health':
            return IconHealth
        case 'property':
            return IconProperty
        case 'responsibility':
            return IconResponsibility
        case 'transport':
            return IconTransport
        case 'cargo':
            return IconCargo
        case 'travel':
            return IconTravel
        case 'cyber':
            return IconCyber
        case 'clinics':
            return IconClinics
        case 'doctors':
            return IconDoctors
        case 'benefit':
            return IconBenefit
        case 'private-managers':
            return IconPrivateManagers
        case 'buildings':
            return IconBuildings
        case 'machine':
            return IconMachine
        case 'technic':
            return IconTechnic
        case 'stocks':
            return IconStocks
        case 'contents':
            return IconContents
        case 'stained-glass':
            return IconStainedGlass
        case 'service-centers':
            return IconServiceCenters
        case 'auto-assistance':
            return IconBackAutoAssistance

        default:
            return null
    }
}

const IconByName: FC<{ icon: string; className?: string; onClick?: () => void }> = ({ icon, className, onClick }) => {
    const SelectedIcon = getIcon(icon)
    if (!SelectedIcon) {
        return null
    }

    return <SelectedIcon className={className} onClick={onClick} />
}

interface StyledIconProps {
    icon: string
    width?: string
    height?: string
    size?: string
    maxWidth?: string
    cursorPointer?: boolean
    padding?: string
    margin?: string
    mt?: string
    mb?: string
    ml?: string
    mr?: string
    color?: string
    hoverColor?: string
    noFill?: boolean
    onClick?: () => void
}

const StyledIcon = styled(IconByName)<StyledIconProps>`
    box-sizing: border-box;
    width: ${({ width, size }) => width || size || '16px'};
    height: ${({ height, size }) => height || size || '16px'};
    min-width: ${({ maxWidth, size }) => (!maxWidth && size) || '16px'};
    min-height: ${({ size }) => size || '16px'};
    max-width: ${({ maxWidth }) => maxWidth};
    ${({ cursorPointer }) => (cursorPointer ? 'cursor: pointer;' : '')}
    ${({ padding }) => padding && `padding: ${padding};`}
    ${({ margin }) => margin && `margin: ${margin};`}
    ${({ mt }) => mt && `margin-top: ${mt};`}
    ${({ mb }) => mb && `margin-bottom: ${mb};`}
    ${({ ml }) => ml && `margin-left: ${ml};`}
    ${({ mr }) => mr && `margin-right: ${mr};`}

    & path {
        fill: ${({ color, noFill }) => color || (!noFill && 'white')};
    }

    &:hover {
        & path {
            fill: ${({ hoverColor, color, noFill }) => hoverColor || color || (!noFill && 'white')};
        }
    }
`

interface IconProps extends StyledIconProps {}

const Icon = (props: IconProps) => <StyledIcon {...props} />

export default Icon
