import { useLayoutEffect, useState } from 'react'

interface ReturnInterface {
    width: number
    height: number
    isMobile: boolean
    isTablet: boolean
    isDesktop: boolean
    isXS: boolean
    isS: boolean
    isM: boolean
    isL: boolean
    isXL: boolean
}

const useViewport = (): ReturnInterface => {
    const [width, setWidth] = useState<number>(0)
    const [height, setHeight] = useState<number>(0)

    useLayoutEffect(() => {
        const handleWindowResize = (): void => {
            setWidth(window.innerWidth)
            setHeight(window.innerHeight)
        }

        setWidth(window.innerWidth)
        setHeight(window.innerHeight)

        window.addEventListener('resize', handleWindowResize)
        return () => window.removeEventListener('resize', handleWindowResize)
    }, [])

    const isMobile = width >= 50 && width <= 640
    const isTablet = width > 640 && width <= 1007
    const isDesktop = width > 1154

    const isXS = width >= 50 && width <= 420
    const isS = width > 420 && width <= 640
    const isM = width > 640 && width <= 1007
    const isL = width > 1007 && width <= 1500
    const isXL = width > 1500

    return {
        width,
        height,
        isMobile,
        isTablet,
        isDesktop,
        isXS,
        isS,
        isM,
        isL,
        isXL,
    }
}

export default useViewport
