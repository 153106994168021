import { FC, useState } from 'react'
import { Link, To } from 'react-router-dom'
import { ListItem, List, Box, Typography, Stack } from '@mui/material'
import useViewport from 'hooks/useViewport'

interface InfoListProps {
    data: Array<{ content: string; answer?: string | string[]; urlString?: string; url?: string; showAll?: boolean }>
    color: string
    noBold?: boolean
}

const InfoList: FC<InfoListProps> = ({ data, color, noBold }) => {
    const { isMobile } = useViewport()
    const [selectedQuestion, setSelectedQuestion] = useState<any>(null)

    const handleItemClick = (question: {
        content: string
        answer?: string | string[]
        urlString?: string
        url?: string
    }) => {
        setSelectedQuestion(selectedQuestion === question ? null : question)
    }

    return (
        <List>
            {data.map((item) => (
                <Box key={item.content}>
                    <ListItem
                        onClick={() => handleItemClick(item)}
                        style={{
                            cursor: item.answer && !item.showAll ? 'pointer' : 'inherit',
                            display: 'flex',
                            alignItems: 'flex-start',
                        }}
                    >
                        <Stack
                            justifyContent='center'
                            alignItems='center'
                            bgcolor={color ?? 'red'}
                            borderRadius='50%'
                            width='24px'
                            height='24px'
                            marginRight='8px'
                        >
                            <Typography fontSize='16px' color='#FFFFFF' fontFamily='Firago'>
                                {item.answer && !item.showAll ? '?' : ''}
                            </Typography>
                        </Stack>
                        <Stack width='calc(100% - 20px)'>
                            {item.urlString ? (
                                <Typography fontSize='16px' fontWeight={!noBold ? 'bold' : ''} fontFamily='Firago'>
                                    {item.content.split(item.urlString).map((part, index) => {
                                        return (
                                            <Box key={index}>
                                                {index > 0 ? (
                                                    <Stack direction={isMobile ? 'column' : 'row'}>
                                                        <Link
                                                            to={item.url as To}
                                                            color={color}
                                                            onClick={() => window.scrollTo(0, 0)}
                                                            style={{ textDecoration: 'none', color: 'inherit' }}
                                                        >
                                                            <Typography
                                                                fontStyle='italic'
                                                                whiteSpace='nowrap'
                                                                display='inline'
                                                                fontFamily='Firago'
                                                            >
                                                                {item.urlString}
                                                            </Typography>
                                                        </Link>
                                                        {!isMobile && <>&nbsp;</>}
                                                        <Typography fontFamily='Firago'>{part}</Typography>
                                                    </Stack>
                                                ) : (
                                                    <Typography fontFamily='Firago'>{part}</Typography>
                                                )}
                                            </Box>
                                        )
                                    })}
                                </Typography>
                            ) : (
                                <Typography fontSize='16px' fontWeight={!noBold ? 'bold' : ''} fontFamily='Firago'>
                                    {item.content}
                                </Typography>
                            )}
                        </Stack>
                    </ListItem>
                    {(selectedQuestion === item || item.showAll) && item.answer && (
                        <Box ml='16px' mt='8px'>
                            {Array.isArray(item.answer) ? (
                                item.answer.map((text, index) => (
                                    <Typography key={index} fontSize='16px' mb='8px' fontFamily='Firago'>
                                        {`${item.showAll ? index + 1 + '.' : ''} ${text}`}
                                    </Typography>
                                ))
                            ) : (
                                <Typography fontSize='16px' fontFamily='Firago'>
                                    {item.answer}
                                </Typography>
                            )}
                        </Box>
                    )}
                </Box>
            ))}
        </List>
    )
}

export default InfoList
