import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import {
    Main,
    Health,
    PrivateManagers,
    Property,
    Responsibility,
    Transport,
    Cargo,
    Travel,
    TeamMember,
    Cyber,
} from 'pages'

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Main />} />
                <Route path='/health' element={<Health />} />
                <Route path='/private-managers' element={<PrivateManagers />} />
                <Route path='/property' element={<Property />} />
                <Route path='/responsibility' element={<Responsibility />} />
                <Route path='/transport' element={<Transport />} />
                <Route path='/cargo' element={<Cargo />} />
                <Route path='/travel' element={<Travel />} />
                <Route path='/cyber' element={<Cyber />} />
                <Route path='/team-member/:id' element={<TeamMember />} />
            </Routes>
        </BrowserRouter>
    )
}

export default App
