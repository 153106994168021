import { FC, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Stack } from '@mui/material'
import { Footer, Image } from 'components'
import useViewport from 'hooks/useViewport'
import Logo from 'assets/images/logo.png'

interface ContainerProps {
    children: ReactNode
    color?: string
    noFooter?: boolean
    hideReviews?: boolean
}

const Container: FC<ContainerProps> = ({ children, color, noFooter, hideReviews }) => {
    const navigate = useNavigate()
    const { isDesktop, isXS } = useViewport()

    return (
        <Box position='absolute' width='100vw'>
            <Stack
                alignItems='center'
                onClick={() => navigate('/')}
                sx={{ cursor: 'pointer' }}
                position='fixed'
                bgcolor='#F5F5F5'
                py='16px'
                borderBottom='1px solid #2A1B58'
                width='100%'
                zIndex={1}
            >
                <Image src={Logo} alt='Alpha Corp' width='100%' maxWidth={isXS ? '180px' : '250px'} />
            </Stack>
            <Stack margin={isDesktop ? '32px' : '16px'} mt='150px'>
                {children}
            </Stack>
            {!noFooter && <Footer color={color ? color : ''} hideReviews={hideReviews} />}
        </Box>
    )
}
export default Container
