import { FC } from 'react'

import { Image } from 'components'
import { Typography, Stack, Divider, Box } from '@mui/material'
import useViewport from 'hooks/useViewport'

interface ReviewProps {
    companyName: string
    reviewText: string
    author: string
    imageUrl: string
}

const Review: FC<ReviewProps> = ({ companyName, reviewText, author, imageUrl }) => {
    const { isMobile } = useViewport()

    return (
        <>
            <Stack direction='row' alignItems='center' gap='16px' justifyContent='center'>
                <Stack boxShadow={3} borderRadius='50%'>
                    <Image src={imageUrl} alt='Author' borderRadius='50%' height='110px' width='110px' />
                </Stack>
                <Stack direction='column' width='100%' gap='8px'>
                    <Typography fontSize={isMobile ? '12px' : '14px'} fontFamily='Firago'>
                        {companyName}
                    </Typography>
                    <Typography
                        fontWeight='bold'
                        fontSize={isMobile ? '12px' : '14px'}
                        fontStyle='italic'
                        minHeight='110px'
                        display='flex'
                        alignItems='center'
                        fontFamily='Firago'
                    >
                        {reviewText}
                    </Typography>
                    <Stack alignSelf='flex-end'>
                        <Typography fontWeight='bold' fontSize={isMobile ? '12px' : '14px'} fontFamily='Firago'>
                            {author}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
            <Box my='20px'>
                <Divider color='#F5F5F5' />
            </Box>
        </>
    )
}

export default Review
