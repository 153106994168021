import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Stack, Typography } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'

import { ColorBox, Container, Image, InfoList } from 'components'
import { cyberList, team } from 'data'
import useViewport from 'hooks/useViewport'

const Cyber: FC = () => {
    const navigate = useNavigate()
    const { isXS, isMobile } = useViewport()

    const memberInfo = team.find((member) => member.id === 'davit-dolidze')

    return (
        <Container color='#13208E'>
            <Stack direction='row' justifyContent='space-between' alignItems='center' mb='32px'>
                <ColorBox
                    icon='cyber'
                    backgroundColor='#13208E'
                    height='100px'
                    width='100px'
                    maxWidth='100px'
                    iconSize='40px'
                    fontSize='14px'
                >
                    კიბერ უსაფრთხოება
                </ColorBox>
                <Button
                    onClick={() => navigate(-1)}
                    sx={{
                        borderRadius: '24px',
                        '&:hover, &:focus': {
                            backgroundColor: '#F5F5F5',
                        },
                    }}
                >
                    <ArrowBack fontSize='large' style={{ color: '#2A1B58' }} />
                </Button>
            </Stack>
            <Typography fontFamily='Firago'>
                კიბერ უსაფრთხოების დაზღვევა 21-ე საუკუნის ყველაზე გავრცელებული რისკისგან - კიბერ თავდასხმისგან დაგიცავთ.
            </Typography>

            <ColorBox
                backgroundColor='#F5F5F5'
                textColor='#13208E'
                height='72px'
                fontSize={isXS ? '20px' : '24px'}
                margin='32px 0'
            >
                რა აგინაზღაურდებათ
            </ColorBox>

            <InfoList data={cyberList} color='#13208E' />

            {memberInfo && (
                <Stack ml={isXS ? '12px' : '32px'}>
                    <Typography
                        fontSize={isXS ? '16px' : '20px'}
                        fontWeight='bold'
                        fontFamily='Firago'
                        my='32px'
                        maxWidth='fit-content'
                        textAlign='center'
                    >
                        დაინტერესების შემთხვევაში დაგვიკავშირდი
                    </Typography>
                    <Stack direction='row' gap={isMobile ? '16px' : '32px'} alignItems='center'>
                        <Image
                            src={memberInfo.image}
                            alt={memberInfo.name}
                            borderRadius='50%'
                            height={isMobile ? '120px' : '150px'}
                            width={isMobile ? '120px' : '150px'}
                        />
                        <Stack
                            direction='column'
                            justifyContent='space-between'
                            alignItems='flex-start'
                            width={isMobile ? 'calc(100% - 150px)' : 'calc(100% - 200px)'}
                        >
                            <Typography fontSize={isMobile ? '24px' : '32px'} fontWeight='bold' fontFamily='Firago'>
                                {memberInfo.name}
                            </Typography>
                            <Typography fontSize={isXS ? '14px' : '16px'} fontStyle='italic' fontFamily='Firago'>
                                {memberInfo.position}
                            </Typography>
                            <ColorBox
                                icon='phone'
                                backgroundColor='#2A1B58'
                                width='fit-content'
                                fontSize='20px'
                                margin='16px 0'
                                flexDirection='row'
                                phone={memberInfo.phone}
                                iconSize='16px'
                            >
                                <Typography fontFamily='Firago' color='white' fontSize='12px'>
                                    დაუკავშირდი {memberInfo.name.split(' ')[0]}ს
                                </Typography>
                            </ColorBox>
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </Container>
    )
}

export default Cyber
