import { FC } from 'react'
import { Typography, Grid, Stack, Link } from '@mui/material'

import { ColorBox, Container, Image } from 'components'
import { products } from 'data'
import useViewport from 'hooks/useViewport'
import Cover from 'assets/images/cover.jpg'
import '../logo.css'

const Main: FC = () => {
    const { isXS, isDesktop, isS, isMobile } = useViewport()

    return (
        <Container color='#2A1B58' hideReviews>
            <Typography
                fontSize='32px'
                pb={!isDesktop ? '16px' : ''}
                color='#2A1B58'
                fontWeight='bold'
                fontFamily='Firago'
                mt={isXS ? '-24px' : ''}
            >
                ალფა კორპი
            </Typography>
            <Stack
                direction={isDesktop ? 'row' : 'column-reverse'}
                alignItems='center'
                gap={isDesktop ? '80px' : '32px'}
                mb='32px'
                fontFamily='Firago'
            >
                <Stack gap='8px' maxWidth={isDesktop ? '650px' : 'inherit'}>
                    <Typography fontFamily='Firago'>
                        ალფა კორპი, სადაზღვევო კომპანია
                        <Typography color='#F41F1F' display='inline-block' fontWeight='bold' fontFamily='Firago'>
                            &nbsp;
                            <Link
                                href='https://www.alpha.ge/'
                                target='_blank'
                                rel='noreferrer'
                                style={{ textDecoration: 'underline', fontStyle: 'italic', color: '#F41F1F' }}
                            >
                                ალფას
                            </Link>
                            &nbsp;
                        </Typography>
                        ახალი მიმართულებაა, კორპორატიული ბიზნესისთვის, განახლებული და გაუმჯობესებული მომსახურებით.
                    </Typography>
                    <Typography fontFamily='Firago'>
                        ალფა კორპის თითოეული პროდუქტი ინდივიდუალურად მუშავდება პარტნიორის მოთხოვნებისა და საჭიროებების
                        საფუძველზე.
                    </Typography>
                    <Typography fontFamily='Firago'>
                        საუკეთესო პირობების მისაღებად, ჩვენი გუნდი, თქვენთვის, პერსონალურად შეიმუშავებს ექსკლუზიურ
                        წინადადებას.
                    </Typography>
                    <Typography fontFamily='Firago'>
                        სადაზღვევო კომპანია ალფა, 2009 წლიდან ოპერირებს ბაზარზე. ის წარმოადგენს ერთ- ერთი უმსხვილესი
                        ჰოლდინგის
                        <Typography color='#F41F1F' display='inline-block' fontWeight='bold' fontFamily='Firago'>
                            &nbsp;
                            <Link
                                href='https://www.aversi.ge/'
                                target='_blank'
                                rel='noreferrer'
                                style={{ textDecoration: 'underline', fontStyle: 'italic', color: '#F41F1F' }}
                            >
                                ავერსის
                            </Link>
                            &nbsp;
                        </Typography>
                        შვილობილ კომპანიას.
                    </Typography>
                    <Typography fontFamily='Firago'>
                        სამედიცინო, ფარმაცევტული და სადაზღვევო მიმართულებების სინერგია, ხელს გვიწყობს, თქვენამდე
                        მოვიტანოთ სრულად დახვეწილი პროდუქტები, რომელიც ჩვენი სიამაყის და თქვენი კმაყოფილების უპირობო
                        გარანტიაა.
                    </Typography>
                </Stack>
                <Image
                    src={Cover}
                    alt='Alpha Corp'
                    width='100%'
                    maxHeight={isDesktop ? '380px' : 'inherit'}
                    borderRadius={isMobile ? '8px' : ''}
                />
            </Stack>
            <Stack
                justifyContent='center'
                alignItems='center'
                bgcolor='#F5F5F5'
                borderRadius='24px'
                mb='32px'
                padding={isDesktop ? '16px 40px' : '16px'}
            >
                <ColorBox
                    textColor='#2A1B58'
                    backgroundColor='transparent'
                    width='fit-content'
                    fontSize={isXS ? '20px' : '32px'}
                    margin={isXS ? '0 0 16px 0' : '0 0 32px 0'}
                >
                    ალფა კორპის პროდუქტები
                </ColorBox>

                <Grid
                    container
                    spacing={2}
                    maxWidth='2036px'
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    {products.map((product) => (
                        <Grid item key={product.icon} xs={isDesktop ? 3 : isXS || isS ? 6 : 4}>
                            <ColorBox
                                icon={product.icon}
                                backgroundColor={product.color}
                                height={isXS ? '80px' : '100px'}
                                fontSize={
                                    !isMobile
                                        ? '16px'
                                        : isXS
                                        ? product.icon === 'responsibility'
                                            ? '10px'
                                            : '12px'
                                        : '14px'
                                }
                                iconSize={!isMobile ? '64px' : isXS ? '32px' : '48px'}
                                to={product.toUrl}
                                padding={isXS ? '8px' : '12px'}
                            >
                                {product.title}
                            </ColorBox>
                        </Grid>
                    ))}
                </Grid>
            </Stack>
        </Container>
    )
}

export default Main
