import { FC } from 'react'
import { Link } from 'react-router-dom'
import { Box, Grid, Stack, Typography } from '@mui/material'

import { ColorBox, Image, Review } from 'components'
import { reviewers, team } from 'data'
import useViewport from 'hooks/useViewport'
import Logo from 'assets/images/logo.png'

interface FooterProps {
    color: string
    hideReviews?: boolean
}

const Footer: FC<FooterProps> = ({ color, hideReviews }) => {
    const { isDesktop, isXS, isMobile } = useViewport()

    return (
        <Stack direction='column' gap='24px' margin={isDesktop ? '32px' : '16px'}>
            {!hideReviews && (
                <Box>
                    <ColorBox
                        backgroundColor='#F5F5F5'
                        textColor={color}
                        height='72px'
                        fontSize={isXS ? '20px' : '24px'}
                        margin='16px 0'
                    >
                        დაზღვეულები ჩვენ შესახებ
                    </ColorBox>
                    <Stack direction='column' borderRadius='16px' padding='16px' pb='0px'>
                        <Grid container spacing={isDesktop ? 4 : 0}>
                            {reviewers.map((review) => (
                                <Grid item key={review.companyName} xs={isDesktop ? 6 : 12}>
                                    <Review
                                        companyName={review.companyName}
                                        reviewText={review.reviewText}
                                        author={review.author}
                                        imageUrl={review.imageUrl}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Stack>
                </Box>
            )}
            <Stack
                justifyContent='center'
                alignItems='center'
                bgcolor='#F5F5F5'
                borderRadius='24px'
                padding='16px'
                flexDirection={isDesktop ? 'row' : 'column'}
            >
                <ColorBox
                    textColor={color}
                    backgroundColor='transparent'
                    width='fit-content'
                    fontSize={isXS ? '20px' : '32px'}
                    margin={isXS ? '0 0 16px 0' : '0 0 32px 0'}
                >
                    ალფა კორპის გუნდი
                </ColorBox>

                <Grid container spacing={2}>
                    {team.map((member) => (
                        <Grid item key={member.id} lg={3} md={3} sm={6} xs={6}>
                            <Link
                                to={`/team-member/${member.id}`}
                                onClick={() => window.scrollTo(0, 0)}
                                style={{ textDecoration: 'none', color: 'inherit' }}
                            >
                                <Stack
                                    justifyContent='center'
                                    alignItems='center'
                                    gap='8px'
                                    style={{ cursor: 'pointer' }}
                                >
                                    <Image
                                        src={member.image}
                                        alt={member.name}
                                        borderRadius='50%'
                                        height={isMobile ? (isXS ? '100px' : '150px') : '200px'}
                                        width={isMobile ? (isXS ? '100px' : '150px') : '200px'}
                                    />
                                    <Typography
                                        fontWeight='bold'
                                        textAlign='center'
                                        style={{ textDecoration: isDesktop ? 'underline' : 'none' }}
                                        fontFamily='Firago'
                                        fontSize={isXS ? '12px' : 'inherit'}
                                    >
                                        {member.name}
                                    </Typography>
                                </Stack>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </Stack>
            <Stack
                justifyContent='center'
                alignItems={isMobile ? 'center' : 'flex-end'}
                padding='16px'
                paddingRight={!isMobile ? '32px' : ''}
            >
                <Image src={Logo} alt='Alpha Corp' width='100%' maxWidth={isXS ? '160px' : '180px'} />
            </Stack>
        </Stack>
    )
}

export default Footer
