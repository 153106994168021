import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Grid, Stack, Typography } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'

import { ColorBox, Container, Image, InfoList } from 'components'
import { privateManagers, privateManagersList } from 'data'
import useViewport from 'hooks/useViewport'

const PrivateManagers: FC = () => {
    const navigate = useNavigate()
    const { isDesktop, isXS } = useViewport()

    return (
        <Container color='#FF0052'>
            <Stack direction='row' justifyContent='space-between' alignItems='center' mb='32px'>
                <ColorBox
                    icon='private-managers'
                    backgroundColor='#FF0052'
                    height='100px'
                    width='100px'
                    maxWidth='100px'
                    fontSize='12px'
                >
                    პირადი მენეჯერები
                </ColorBox>
                <Button
                    onClick={() => navigate(-1)}
                    sx={{
                        borderRadius: '24px',
                        '&:hover, &:focus': {
                            backgroundColor: '#F5F5F5',
                        },
                    }}
                >
                    <ArrowBack fontSize='large' style={{ color: '#2A1B58' }} />
                </Button>
            </Stack>

            <Grid container spacing={2}>
                {privateManagers.map((manager) => (
                    <Grid item key={manager.name} xs={isDesktop ? 3 : 6}>
                        <Stack direction='column' alignItems='center' gap='8px'>
                            <Image
                                src={manager.image}
                                alt={manager.name}
                                borderRadius='50%'
                                height={isXS ? '150px' : '200px'}
                                width={isXS ? '150px' : '200px'}
                            />
                            <Typography color='#FF0052' fontWeight='bold' fontStyle='italic' fontFamily='Firago'>
                                {manager.name}
                            </Typography>
                        </Stack>
                    </Grid>
                ))}
            </Grid>

            <ColorBox
                backgroundColor='#F5F5F5'
                textColor='#FF0052'
                height='72px'
                fontSize={isXS ? '20px' : '24px'}
                margin='32px 0'
            >
                რა შემთხვევაში უნდა მიმართოთ პირად მენეჯერს?
            </ColorBox>

            <InfoList data={privateManagersList} color='#FF0052' />
        </Container>
    )
}

export default PrivateManagers
