import { FC, ReactNode } from 'react'
import { Link, To } from 'react-router-dom'
import { Icon } from 'components'
import { Stack, Typography } from '@mui/material'

interface ColorBoxProps {
    icon?: string
    children?: ReactNode
    width?: string
    height?: string
    maxWidth?: string
    minWidth?: string
    backgroundColor: string
    textColor?: string
    padding?: string
    margin?: string
    fontSize?: string
    border?: string
    iconSize?: string
    minHeight?: string
    flexDirection?: 'column' | 'row' | 'row-reverse' | 'column-reverse'
    to?: string
    phone?: string
    onClick?: () => void
}

const CustomComponent: FC<ColorBoxProps> = ({
    children,
    icon,
    textColor,
    fontSize,
    width,
    height,
    padding,
    iconSize,
    minHeight,
    flexDirection,
    to,
    onClick,
    phone,
    ...rest
}) => {
    const content = (
        <Stack
            {...rest}
            direction={flexDirection ?? 'column'}
            justifyContent='center'
            alignItems='center'
            gap='12px'
            borderRadius='24px'
            width={width}
            height={height}
            padding={padding ?? '12px'}
            onClick={onClick}
            style={{ cursor: onClick ? 'pointer' : 'inherit' }}
        >
            {icon && <Icon icon={icon} size={iconSize ?? '32px'} color={textColor} />}
            {children && (
                <Typography
                    color={textColor || '#FFFFFF'}
                    fontSize={fontSize}
                    textAlign='center'
                    fontWeight='bold'
                    minHeight={minHeight}
                    fontFamily='Firago'
                >
                    {children}
                </Typography>
            )}
        </Stack>
    )

    return phone ? (
        <a href={`tel:${phone}`} style={{ textDecoration: 'none', color: 'inherit' }}>
            {content}
        </a>
    ) : (
        <Link to={to as To} onClick={() => window.scrollTo(0, 0)} style={{ textDecoration: 'none', color: 'inherit' }}>
            {content}
        </Link>
    )
}

export default CustomComponent
