import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Stack, Typography } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'

import { ColorBox, Container, InfoList } from 'components'
import { transportList } from 'data'
import useViewport from 'hooks/useViewport'

const Transport: FC = () => {
    const navigate = useNavigate()
    const { isXS } = useViewport()

    return (
        <Container color='#FFC600'>
            <Stack direction='row' justifyContent='space-between' alignItems='center' mb='32px'>
                <ColorBox
                    icon='transport'
                    backgroundColor='#FFC600'
                    height='100px'
                    width='100px'
                    maxWidth='100px'
                    iconSize='40px'
                    fontSize='14px'
                >
                    ტრანსპორტი
                </ColorBox>
                <Button
                    onClick={() => navigate(-1)}
                    sx={{
                        borderRadius: '24px',
                        '&:hover, &:focus': {
                            backgroundColor: '#F5F5F5',
                        },
                    }}
                >
                    <ArrowBack fontSize='large' style={{ color: '#2A1B58' }} />
                </Button>
            </Stack>
            <Typography fontFamily='Firago'>
                სატრანსპორტო საშუალებების დაზღვევა იცავს თქვენს ბიზნესს გაუთვალისწინებელი ფინანსური ხარჯებისა და ბიზნეს
                საქმიანობის შეფერხებებისგან.
            </Typography>

            <ColorBox
                backgroundColor='#F5F5F5'
                textColor='#FFC600'
                height='72px'
                fontSize={isXS ? '20px' : '24px'}
                margin='32px 0'
            >
                რას გთავაზობთ?
            </ColorBox>

            <InfoList data={transportList} color='#FFC600' />
        </Container>
    )
}

export default Transport
