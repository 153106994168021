import { FC } from 'react'

interface ImageProps {
    src: string
    alt: string
    width?: string
    height?: string
    borderRadius?: string
    maxWidth?: string
    maxHeight?: string
}

const Image: FC<ImageProps> = ({ src, alt, width, height, borderRadius, maxWidth, maxHeight }) => {
    return (
        <img
            src={src}
            alt={alt}
            width={width ?? '100%'}
            height={height ?? '100%'}
            style={{
                borderRadius: borderRadius,
                maxWidth: maxWidth,
                maxHeight: maxHeight,
                boxSizing: 'border-box',
                objectFit: 'cover',
                overflow: 'hidden',
            }}
        />
    )
}

export default Image
